var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "13",
        height: "12",
        viewBox: "0 0 13 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_3997_277)" } }, [
        _c("path", {
          attrs: {
            d: "M6.79248 11C9.5539 11 11.7925 8.76142 11.7925 6C11.7925 3.23858 9.5539 1 6.79248 1C4.03106 1 1.79248 3.23858 1.79248 6C1.79248 8.76142 4.03106 11 6.79248 11Z",
            stroke: "inherit",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M1.79248 6H11.7925",
            stroke: "inherit",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M6.79248 1C8.04312 2.36918 8.75386 4.14602 8.79248 6C8.75386 7.85398 8.04312 9.63082 6.79248 11C5.54184 9.63082 4.8311 7.85398 4.79248 6C4.8311 4.14602 5.54184 2.36918 6.79248 1V1Z",
            stroke: "inherit",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_3997_277" } }, [
          _c("rect", {
            attrs: {
              width: "12",
              height: "12",
              fill: "white",
              transform: "translate(0.79248)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }