var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "light_gray", elevation: "0" } },
    [
      _c(
        "v-form",
        { ref: "deliveryAddressForm" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "12" } }),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { md: "6", cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      label: _vm.$t("label.recipientName"),
                      placeholder: _vm.$t("label.name"),
                      rules: _vm.$validation.nameRules(),
                    },
                    model: {
                      value: _vm.deliveryAddressForm.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.deliveryAddressForm,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "deliveryAddressForm.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { md: "6", cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      prefix: _vm.deliveryAddressForm.phoneCode,
                      label: _vm.$t("label.phone"),
                      placeholder: _vm.$t("label.phone"),
                      rules: _vm.$validation.phoneRules(),
                    },
                    model: {
                      value: _vm.deliveryAddressForm.phone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.deliveryAddressForm,
                          "phone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "deliveryAddressForm.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      label: _vm.$t("label.address"),
                      placeholder: _vm.$t("label.streetBulidngName"),
                      rules: _vm.$validation.addressRules(),
                    },
                    model: {
                      value: _vm.deliveryAddressForm.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.deliveryAddressForm,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "deliveryAddressForm.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { md: "6", cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      isOptional: "",
                      label: _vm.$t("label.block"),
                      placeholder: _vm.$t("label.block"),
                      rules: _vm.$validation.addressBlockRules(),
                    },
                    model: {
                      value: _vm.deliveryAddressForm.block,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.deliveryAddressForm,
                          "block",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "deliveryAddressForm.block",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { md: "6", cols: "12" } },
                [
                  _c("app-form-field", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "#####",
                        expression: "'#####'",
                      },
                    ],
                    attrs: {
                      label: _vm.$t("label.postcode"),
                      placeholder: _vm.$t("label.postcode"),
                      rules: _vm.$validation.postcodeRules(),
                    },
                    model: {
                      value: _vm.deliveryAddressForm.postcode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.deliveryAddressForm,
                          "postcode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "deliveryAddressForm.postcode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { md: "6", cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      label: _vm.$t("label.city"),
                      placeholder: _vm.$t("label.city"),
                      rules: _vm.$validation.requiredRules(),
                    },
                    model: {
                      value: _vm.deliveryAddressForm.city,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.deliveryAddressForm,
                          "city",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "deliveryAddressForm.city",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { md: "6", cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      label: _vm.$t("label.state"),
                      type: "select",
                      items: _vm.states,
                      placeholder: _vm.$t("label.state"),
                      rules: _vm.$validation.dropDownListRules(),
                    },
                    model: {
                      value: _vm.deliveryAddressForm.state,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.deliveryAddressForm,
                          "state",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "deliveryAddressForm.state",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { cols: "12" } },
                [
                  _c("v-switch", {
                    staticClass: "mt-0 mb-2",
                    attrs: {
                      readonly: _vm.isFirstAddress,
                      dense: "",
                      flat: "",
                      "hide-details": "",
                      label: _vm.$t("label.defaultShippingAddress"),
                    },
                    model: {
                      value: _vm.deliveryAddressForm.defaultShippingAddress,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.deliveryAddressForm,
                          "defaultShippingAddress",
                          $$v
                        )
                      },
                      expression: "deliveryAddressForm.defaultShippingAddress",
                    },
                  }),
                  _c("v-switch", {
                    staticClass: "mt-0 mb-2",
                    attrs: {
                      readonly: _vm.isFirstAddress,
                      dense: "",
                      flat: "",
                      "hide-details": "",
                      label: _vm.$t("label.defaultBillingAddress"),
                    },
                    model: {
                      value: _vm.deliveryAddressForm.defaultBillingAddress,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.deliveryAddressForm,
                          "defaultBillingAddress",
                          $$v
                        )
                      },
                      expression: "deliveryAddressForm.defaultBillingAddress",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "full-width white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8",
                        attrs: {
                          height: "56px",
                          "max-width": "300px",
                          color: "unbox_black",
                          elevation: "0",
                        },
                        on: { click: _vm.submitDeliveryAddress },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("label.save")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }