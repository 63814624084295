var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "18",
        viewBox: "0 0 18 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_3997_142)" } }, [
        _c("path", {
          attrs: {
            d: "M11.9978 2.98875H13.641V0.12675C13.3575 0.08775 12.3825 0 11.247 0C8.87776 0 7.25476 1.49025 7.25476 4.22925V6.75H4.64026V9.9495H7.25476V18H10.4603V9.95025H12.969L13.3673 6.75075H10.4595V4.5465C10.4603 3.62175 10.7093 2.98875 11.9978 2.98875V2.98875Z",
            fill: "white",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_3997_142" } }, [
          _c("rect", { attrs: { width: "18", height: "18", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }