var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "22",
        height: "23",
        viewBox: "0 0 22 23",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M11 20.9267C16.0626 20.9267 20.1667 16.8226 20.1667 11.76C20.1667 6.6974 16.0626 2.59335 11 2.59335C5.9374 2.59335 1.83334 6.6974 1.83334 11.76C1.83334 16.8226 5.9374 20.9267 11 20.9267Z",
          stroke: "inherit",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11 8.09335V15.4267",
          stroke: "inherit",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M7.33334 11.76H14.6667",
          stroke: "inherit",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }