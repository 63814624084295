var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "21",
        height: "21",
        viewBox: "0 0 21 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M17.9258 12.687V16.0203C17.9258 16.4624 17.7502 16.8863 17.4376 17.1989C17.1251 17.5114 16.7011 17.687 16.2591 17.687H4.59245C4.15042 17.687 3.7265 17.5114 3.41394 17.1989C3.10138 16.8863 2.92578 16.4624 2.92578 16.0203V12.687",
          stroke: "#94A3B8",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.25903 8.52032L10.4257 12.687L14.5924 8.52032",
          stroke: "#94A3B8",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.4258 12.687V2.68701",
          stroke: "#94A3B8",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }