var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "13",
        height: "13",
        viewBox: "0 0 13 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_3997_285)" } }, [
        _c("path", {
          attrs: {
            d: "M2.37598 2.95996H10.376C10.926 2.95996 11.376 3.40996 11.376 3.95996V9.95996C11.376 10.51 10.926 10.96 10.376 10.96H2.37598C1.82598 10.96 1.37598 10.51 1.37598 9.95996V3.95996C1.37598 3.40996 1.82598 2.95996 2.37598 2.95996Z",
            stroke: "inherit",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("rect", {
          attrs: {
            x: "7.91602",
            y: "5.77246",
            width: "3.33499",
            height: "2.375",
            rx: "1.1875",
            stroke: "inherit",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_3997_285" } }, [
          _c("rect", {
            attrs: {
              width: "12",
              height: "12",
              fill: "white",
              transform: "translate(0.375977 0.959961)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }