import { API, SHARED } from '@/constants'
import { apiHelper } from '@/utils'

const API_URL = API.BASE_URL.replace('@serviceName', 'orders') + '/v1'

const ORDER = {
  LOOKUPS: 'lookups',
  DELIVERY_METHODS: 'delivery-methods',
  ORDER_STATUSES: 'order-statuses',
  ORDER: 'orders',
  NORMAL: 'normal',
  STATUS: 'status',
  PAYMENTS: 'payments',
  CANCELLATIONS: 'cancellations',
  VOID: 'void',
  REFUND: 'refund',
  PRE: 'pre',
  CANCEL: 'cancel',
  CARTS: 'carts',
  CART_STATUSES: 'cart-statuses'
}
export function cancelOrder(data) {
  let requestOptions = {
    order_uuid: data.orderUuid,
    return_type: data.returnType,
    products: data.product,
    platform: SHARED.PLATFORM.toLowerCase(),
    remark: data.remark || '-'
  }

  return apiHelper(API_URL, true).post(`/${ORDER.CANCELLATIONS}/${ORDER.CANCEL}`, requestOptions)
}

export function voidOrder(data) {
  let requestOptions = {
    order_uuid: data.orderUuid,
    return_type: data.returnType,
    platform: SHARED.PLATFORM.toLowerCase(),
    remark: data.remark || '-'
  }
  return apiHelper(API_URL, true).post(`/${ORDER.CANCELLATIONS}/${ORDER.VOID}`, requestOptions)
}

export function refundOrder(data) {
  let requestOptions = {
    order_uuid: data.orderUuid,
    return_type: data.returnType,
    products: data.product,
    refund_amount: data.refundAmount,
    is_policy_check: true,
    platform: SHARED.PLATFORM.toLowerCase(),
    remark: data.remark || '-'
  }

  return apiHelper(API_URL, true).post(`/${ORDER.CANCELLATIONS}/${ORDER.REFUND}`, requestOptions)
}

export function placePreOrder(data) {
  let requestOptions = {
    reference_number: '',
    drawer_uuid: '',
    currency: data.currency,
    products: data.product,
    deposit_amount: data.depositAmount != 0 ? data.depositAmount : 0,
    discount_code: data.dicountCode,
    discount_percentage: 0,
    discount_amount: 0,
    discount_remark: '',
    mark_up_price: 0,
    mark_up_percentage: 0,
    mark_up_remark: '',
    surcharge_price: data.surchargePrice,
    surcharge_percentage: data.surchargePercentage,
    surcharge_remark: data.surchargeRemark,
    remark: data.product[0].remark,
    platform: SHARED.PLATFORM.toLowerCase(),
    email: data.contactEmail,
    phone_number: data.contactPhoneNumber,
    billing_customer_name: data.billingName,
    billing_address: data.billingAddress,
    billing_phone_number: data.billingPhoneNumber
  }
  return apiHelper(API_URL, true).post(`/${ORDER.ORDER}/${ORDER.PRE}`, requestOptions)
}

export function placeNormalOrder(data) {
  let requestOptions = {
    reference_number: '',
    linkage_ref_number: data.linkageRefNumber,
    drawer_uuid: '',
    currency: data.currency,
    products: data.product,
    deposit_amount: data.depositAmount,
    discount_code: data.dicountCode,
    discount_percentage: 0,
    discount_amount: data.discountAmount,
    discount_remark: '',
    mark_up_price: 0,
    mark_up_percentage: 0,
    mark_up_remark: '',
    surcharge_price: data.surchargePrice,
    surcharge_percentage: data.surchargePercentage,
    surcharge_remark: data.surchargeRemark,
    remark: '',
    platform: SHARED.PLATFORM.toLowerCase(),
    email: data.contactEmail,
    phone_number: data.contactPhoneNumber,
    delivery_method: data.deliveryMethod,
    outlet_uuid: data.outletId,
    billing_customer_name: data.billingName,
    billing_address: data.billingAddress,
    billing_phone_number: data.billingPhoneNumber,
    postage_customer_name: data.shippingName,
    postage_address: data.shippingAddress,
    postage_phone_number: data.shippingPhoneNumber,
    cart_uuids: data.cartUuids
  }
  return apiHelper(API_URL, true).post(`/${ORDER.ORDER}/${ORDER.NORMAL}`, requestOptions)
}

export function getOrderHistory(data) {
  let requestOptions = {
    params: {
      page: data.page,
      size: data.size,
      order_statuses_uuids: data.status,
      sortBy: 'created_date_time',
      orderBy: 'desc',
      is_linkage_ref_exists: data.isLinkageRefExists != null ? (data.isLinkageRefExists ? 1 : 0) : undefined,
      is_cancellation_exists: data.isCancellationExists != null ? (data.isCancellationExists ? 1 : 0) : undefined,
      transaction_types_uuid: data.transactionTypesUuid
    }
  }
  return apiHelper(API_URL, true).get(`/${ORDER.ORDER}`, requestOptions)
}

export function getCancellationOrderHistory(data) {
  let requestOptions = {
    params: {
      page: data.page,
      size: data.size,
      order_statuses_uuids: data.orderStatusesUuids,
      sortBy: 'created_date_time',
      orderBy: 'desc'
    }
  }
  return apiHelper(API_URL, true).get(`/${ORDER.CANCELLATIONS}`, requestOptions)
}

export function getOrderInfo(data) {
  return apiHelper(API_URL, true).get(`/${ORDER.ORDER}/${data.orderUuid}`)
}

export function updateOrderStatus(data) {
  let requestOptions = {
    is_send_email: data.isSendEmail,
    is_order_sealed: data.isOrderSealed
  }
  return apiHelper(API_URL, true).put(`/${ORDER.ORDER}/${data.orderUuid}/${ORDER.STATUS}`, requestOptions)
}

export function createOrderPayment(data) {
  let requestOptions = {
    order_uuid: data.orderUuid,
    payment_method: data.paymentMethod,
    payment_type: data.paymentType,
    amount: data.amount,
    payment_uuid: data.paymentUuid,
    reference_number: data.referenceNumber,
    payment_terminal_vendor_uuid: '',
    payment_gateway_routing_uuid: data.paymentGatewayRoutingUuid
  }
  return apiHelper(API_URL, true).post(`/${ORDER.PAYMENTS}`, requestOptions)
}
export function resendReceipt(data) {
  const requestOptions = {
    email: data.email,
    billing_customer_name: data.billingCustomerName,
    billing_address: data.billingAddress,
    billing_phone_number: data.billingPhoneNumber,
    postage_customer_name: data.postageCustomerName,
    postage_address: data.postageAddress,
    postage_phone_number: data.postagePhoneNumber
  }

  return apiHelper(API_URL).patch(`${ORDER.ORDER}/${data.uuid}/receipt`, requestOptions)
}

/* LOOKUP */
export function getDeliveryMethodsLookup() {
  return apiHelper(API_URL, true).get(`/${ORDER.LOOKUPS}/${ORDER.DELIVERY_METHODS}`)
}

export function getOrderStatuses() {
  return apiHelper(API_URL, true).get(`/${ORDER.LOOKUPS}/${ORDER.ORDER_STATUSES}`)
}

export function lookupReturnType() {
  const requestOptions = {
    params: {
      platform: 'web'
    }
  }
  return apiHelper(API_URL, true).get(`/${ORDER.LOOKUPS}/return-types`, requestOptions)
}

export function getCartStatuses() {
  return apiHelper(API_URL, true).get(`/${ORDER.LOOKUPS}/${ORDER.CART_STATUSES}`)
}

/* CART */
export function getCarts(filter) {
  let requestOptions = {
    params: {
      platform: SHARED.CART_PLATFORM.toLowerCase(),
      page: filter.page,
      size: filter.size,
      cart_statuses_uuid: filter.cartStatusesUuid,
      sortBy: 'created_date_time',
      orderBy: 'desc'
    }
  }
  return apiHelper(API_URL).get(`/${ORDER.CARTS}`, requestOptions)
}

export function createCart(data) {
  let requestOptions = {
    currency: 'MYR',
    platform: SHARED.CART_PLATFORM,
    email: data.email,
    phone_number: data.phoneNumber,
    customer_name: data.customerName,
    discount_code: data.discountCode,
    discount_percentage: data.discountPercentage,
    discount_amount: data.discountAmount,
    mark_up_price: data.markUpPrice,
    mark_up_percentage: data.markUpPercentage,
    remark: data.remark,
    items: data.items.map((i) => ({
      display_sku: i.displaySku,
      quantity: i.quantity,
      discount_code: i.discountCode,
      discount_percentage: i.discountPercentage,
      discount_amount: i.discountAmount,
      mark_up_price: i.markUpPrice,
      mark_up_percentage: i.markUpPercentage,
      product_type: i.productType,
      remark: i.remark,
      services: i.services,
      product_remark: i.productRemark
    }))
  }
  return apiHelper(API_URL).post(`/${ORDER.CARTS}`, requestOptions)
}

export function addCartItem(data) {
  let requestOptions = {
    platform: SHARED.CART_PLATFORM,
    items: data.items.map((i) => ({
      display_sku: i.displaySku,
      quantity: i.quantity,
      discount_code: i.discountCode,
      discount_percentage: i.discountPercentage,
      discount_amount: i.discountAmount,
      mark_up_price: i.markUpPrice,
      mark_up_percentage: i.markUpPercentage,
      product_type: i.productType,
      remark: i.remark,
      services: i.services,
      product_remark: i.productRemark
    }))
  }
  return apiHelper(API_URL).post(`/${ORDER.CARTS}/${data.uuid}/items`, requestOptions)
}

export function updateCartItem(data) {
  let requestOptions = {
    platform: SHARED.CART_PLATFORM.toLowerCase(),
    quantity: data.quantity,
    discount_code: data.discountCode,
    discount_percentage: data.discountPercentage,
    discount_amount: data.discountAmount,
    mark_up_price: data.markUpPrice,
    mark_up_percentage: data.markUpPercentage,
    services: data.services,
    remark: data.remark
  }
  return apiHelper(API_URL).patch(`/${ORDER.CARTS}/${data.cartUuid}/items/${data.itemUuid}`, requestOptions)
}

export function removeCartItem(data) {
  return apiHelper(API_URL).delete(`/${ORDER.CARTS}/${data.uuid}/items/${data.itemUuid}`)
}
