var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "23",
        height: "22",
        viewBox: "0 0 23 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "0.499878",
          width: "22",
          height: "22",
          rx: "4",
          fill: "#769B6E",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M9.67219 13.8999L16.466 7L17.4999 8.05004L9.67219 16L5.49988 11.7625L6.53377 10.7125L9.67219 13.8999Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }