var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "13",
        height: "13",
        viewBox: "0 0 13 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_4312_934)" } }, [
        _c("g", { attrs: { "clip-path": "url(#clip1_4312_934)" } }, [
          _c("path", {
            attrs: {
              d: "M2.875 2.5H10.875C11.425 2.5 11.875 2.95 11.875 3.5V9.5C11.875 10.05 11.425 10.5 10.875 10.5H2.875C2.325 10.5 1.875 10.05 1.875 9.5V3.5C1.875 2.95 2.325 2.5 2.875 2.5Z",
              stroke: "inherit",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
          _c("rect", {
            attrs: {
              x: "8.66504",
              y: "5.5625",
              width: "2.83499",
              height: "1.875",
              rx: "0.9375",
              stroke: "inherit",
              "stroke-width": "1.5",
            },
          }),
        ]),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_4312_934" } }, [
          _c("rect", {
            attrs: {
              width: "12",
              height: "12",
              fill: "white",
              transform: "translate(0.875 0.5)",
            },
          }),
        ]),
        _c("clipPath", { attrs: { id: "clip1_4312_934" } }, [
          _c("rect", {
            attrs: {
              width: "12",
              height: "12",
              fill: "white",
              transform: "translate(0.875 0.5)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }