import { orderService } from '@/services'
import { localeHelper, sharedHelper } from '@/utils'
import { EXTERNAL_PRODUCT } from '@/constants'
import { SHARED_CLOSE_DIALOG, SHARED_SHOW_DIALOG } from './shared.module'
const MODULE_NAME = 'order/'

const A_ORDER_NORMAL_ORDER = 'normalOrder'
const A_ORDER_GET_HISTORY = 'orderGetHistory'
const A_ORDER_GET_ORDER_INFO = 'orderGetOrderInfo'
const A_ORDER_UPDATE_STATUS = 'orderUpdateStatus'
const A_ORDER_CREATE_PAYMENT_ORDER = 'orderCreatePaymentOrder'
const A_ORDER_GET_CANCELLATIONS_HISTORY = 'orderGetCancellationsHistory'
const A_ORDER_VOID_ORDER = 'orderVoidOrder'
const A_ORDER_REFUND_ORDER = 'orderRefundOrder'
const A_ORDER_PRE_ORDER = 'preOrder'
const A_ORDER_CANCEL_ORDER = 'orderCancelSuccessOrder'
const A_ORDER_RESEND_RECEIPT = 'orderResendReceipt'
const A_ORDER_GET_CART = 'orderGetCart'
const A_ORDER_CREATE_CART = 'orderCreateCart'
const A_ORDER_ADD_CART_ITEM = 'orderAddCartItem'
const A_ORDER_UPDATE_CART_ITEM = 'orderUpdateCartItem'
const A_ORDER_REMOVE_CART_ITEM = 'orderDeleteCartItem'

export const ORDER_NORMAL_ORDER = MODULE_NAME + A_ORDER_NORMAL_ORDER
export const ORDER_GET_HISTORY = MODULE_NAME + A_ORDER_GET_HISTORY
export const ORDER_GET_ORDER_INFO = MODULE_NAME + A_ORDER_GET_ORDER_INFO
export const ORDER_UPDATE_STATUS = MODULE_NAME + A_ORDER_UPDATE_STATUS
export const ORDER_CREATE_PAYMENT_ORDER = MODULE_NAME + A_ORDER_CREATE_PAYMENT_ORDER
export const ORDER_GET_CANCELLATIONS_HISTORY = MODULE_NAME + A_ORDER_GET_CANCELLATIONS_HISTORY
export const ORDER_VOID_ORDER = MODULE_NAME + A_ORDER_VOID_ORDER
export const ORDER_REFUND_ORDER = MODULE_NAME + A_ORDER_REFUND_ORDER
export const ORDER_PRE_ORDER = MODULE_NAME + A_ORDER_PRE_ORDER
export const ORDER_CANCEL_ORDER = MODULE_NAME + A_ORDER_CANCEL_ORDER
export const ORDER_RESEND_RECEIPT = MODULE_NAME + A_ORDER_RESEND_RECEIPT
export const ORDER_GET_CART = MODULE_NAME + A_ORDER_GET_CART
export const ORDER_CREATE_CART = MODULE_NAME + A_ORDER_CREATE_CART
export const ORDER_ADD_CART_ITEM = MODULE_NAME + A_ORDER_ADD_CART_ITEM
export const ORDER_UPDATE_CART_ITEM = MODULE_NAME + A_ORDER_UPDATE_CART_ITEM
export const ORDER_REMOVE_CART_ITEM = MODULE_NAME + A_ORDER_REMOVE_CART_ITEM

const M_ORDER_NORMAL_ORDER = 'orderNormalOrderComplete'
const M_ORDER_RESET_NORMAL_ORDER_STORE = 'orderResetNormalOrderStore'
const M_ORDER_GET_HISTORY = 'setOrderHistory'
const M_ORDER_GET_HISTORY_RESET = 'resetOrderHistory'
const M_ORDER_GET_ORDER_INFO = 'orderSetOrderInfo'
const M_ORDER_UPDATE_STATUS = 'orderUpdateStatusComplete'
const M_RESET_ORDER_UPDATE_STATUS_STORE = 'orderResetUpdateStatusStore'
const M_ORDER_CREATE_PAYMENT_ORDER = 'orderCreatePaymentOrderComplete'
const M_ORDER_GET_CANCELLATIONS_HISTORY = 'setOrderCancellationHistory'
const M_ORDER_VOID_ORDER = 'orderVoidOrderComplete'
const M_ORDER_RESET_VOID_ORDER_STORE = 'orderResetVoidOrderStore'
const M_ORDER_REFUND_ORDER = 'orderRefundOrderComplete'
const M_ORDER_RESET_REFUND_ORDER_STORE = 'orderResetRefundOrderStore'
const M_ORDER_PRE_ORDER = 'orderPreOrderComplete'
const M_ORDER_RESET_PRE_ORDER_STORE = 'orderResetPreOrderStore'
const M_ORDER_CANCEL_ORDER = 'orderCancelOrderComplete'
const M_ORDER_RESET_CANCEL_ORDER_STORE = 'orderResetCancelOrderStore'
const M_ORDER_GET_CART = 'setOrderCart'
const M_ORDER_CREATE_CART = 'orderCreateCartComplete'
const M_ORDER_ADD_CART_ITEM = 'orderAddCartItemComplete'
const M_ORDER_UPDATE_CART_ITEM = 'orderUpdateCartItemComplete'
const M_ORDER_REMOVE_CART_ITEM = 'orderDeleteCartItemComplete'
const M_ORDER_RESET_CART = 'orderResetCart'
export const M_ORDER_SET_CART_ITEM_PRICES = 'orderSetCartItemPrices'

const state = {
  order: null,
  cancelOrderResponse: { action: 'cancelOrder', complete: false, success: false, code: 0 },
  refundOrderResponse: { action: 'refundOrder', complete: false, success: false, code: 0 },
  voidOrderResponse: { action: 'voidOrder', complete: false, success: false, code: 0 },
  normalOrderResponse: { action: 'normalOrder', complete: false, success: false, code: 0 },
  preOrderResponse: { action: 'preOrder', complete: false, success: false, code: 0 },
  orderHistory: {
    data: null,
    pagination: { pageNumber: 0, pageSize: 0, lastPage: 0, tableRowsCount: 0 }
  },
  createOrderPaymentResponse: { action: 'createOrderPayment', complete: false, success: false, code: 0 },
  updateOrderStatusResponse: { action: 'updateOrderStatus', complete: false, success: false, code: 0 },
  cart: { items: [] },
  cartWithPrices: { items: [] },
  cartResponse: { complete: false, success: false, code: 0 },
  createCartResponse: { complete: false, success: false, code: 0 },
  addCartItemResponse: { complete: false, success: false, code: 0 },
  updateCartItemResponse: { complete: false, success: false, code: 0 },
  removeCartItemResponse: { complete: false, success: false, code: 0 }
}

const getters = {}

const actions = {
  [A_ORDER_VOID_ORDER]({ commit, dispatch }, { data }) {
    commit(M_ORDER_RESET_VOID_ORDER_STORE)
    sharedHelper.loading(dispatch)
    orderService
      .voidOrder(data)
      .then((result) => {
        commit(M_ORDER_VOID_ORDER, { result })
      })
      .catch((result) => {
        commit(M_ORDER_VOID_ORDER, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_ORDER_REFUND_ORDER]({ commit, dispatch }, { data }) {
    commit(M_ORDER_RESET_REFUND_ORDER_STORE)
    sharedHelper.loading(dispatch)
    orderService
      .refundOrder(data)
      .then((result) => {
        commit(M_ORDER_REFUND_ORDER, { result })
      })
      .catch((result) => {
        commit(M_ORDER_REFUND_ORDER, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_ORDER_NORMAL_ORDER]({ commit, dispatch }, { data }) {
    commit(M_ORDER_RESET_NORMAL_ORDER_STORE)
    sharedHelper.loading(dispatch)
    orderService
      .placeNormalOrder(data)
      .then((result) => {
        commit(M_ORDER_NORMAL_ORDER, { result })
      })
      .catch((result) => {
        commit(M_ORDER_NORMAL_ORDER, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_ORDER_PRE_ORDER]({ commit, dispatch }, { data }) {
    commit(M_ORDER_RESET_PRE_ORDER_STORE)
    sharedHelper.loading(dispatch)
    orderService
      .placePreOrder(data)
      .then((result) => {
        commit(M_ORDER_PRE_ORDER, { result })
      })
      .catch((result) => {
        commit(M_ORDER_PRE_ORDER, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_ORDER_GET_HISTORY]({ commit, dispatch }, { data }) {
    commit(M_ORDER_GET_HISTORY_RESET)
    sharedHelper.loading(dispatch)
    orderService
      .getOrderHistory(data)
      .then((result) => {
        commit(M_ORDER_GET_HISTORY, { result })
      })
      .catch((result) => {
        commit(M_ORDER_GET_HISTORY, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_ORDER_GET_CANCELLATIONS_HISTORY]({ commit, dispatch }, { data }) {
    commit(M_ORDER_GET_HISTORY_RESET)
    sharedHelper.loading(dispatch)
    orderService
      .getCancellationOrderHistory(data)
      .then((result) => {
        commit(M_ORDER_GET_CANCELLATIONS_HISTORY, { result })
      })
      .catch((result) => {
        commit(M_ORDER_GET_CANCELLATIONS_HISTORY, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  async [A_ORDER_GET_ORDER_INFO]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    await orderService
      .getOrderInfo(data)
      .then((result) => {
        commit(M_ORDER_GET_ORDER_INFO, { result })
      })
      .catch((result) => {
        commit(M_ORDER_GET_ORDER_INFO, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  async [A_ORDER_UPDATE_STATUS]({ commit, dispatch }, { data }) {
    commit(M_RESET_ORDER_UPDATE_STATUS_STORE)
    sharedHelper.loading(dispatch)
    await orderService
      .updateOrderStatus(data)
      .then((result) => {
        commit(M_ORDER_UPDATE_STATUS, { result, data })
      })
      .catch((result) => {
        commit(M_ORDER_UPDATE_STATUS, { result, data })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  async [A_ORDER_CREATE_PAYMENT_ORDER]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    await orderService
      .createOrderPayment(data)
      .then((result) => {
        commit(M_ORDER_CREATE_PAYMENT_ORDER, { result })
      })
      .catch((result) => {
        commit(M_ORDER_CREATE_PAYMENT_ORDER, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_ORDER_RESEND_RECEIPT]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    return orderService.resendReceipt(data).finally(() => {
      sharedHelper.unloading(dispatch)
    })
  },
  [A_ORDER_CANCEL_ORDER]({ commit, dispatch }, { data }) {
    commit(M_ORDER_RESET_CANCEL_ORDER_STORE)
    sharedHelper.loading(dispatch)
    orderService
      .cancelOrder(data)
      .then((result) => {
        commit(M_ORDER_CANCEL_ORDER, { result })
      })
      .catch((result) => {
        commit(M_ORDER_CANCEL_ORDER, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_ORDER_RESEND_RECEIPT]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    return orderService.resendReceipt(data).finally(() => {
      sharedHelper.unloading(dispatch)
    })
  },
  [A_ORDER_GET_CART]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    return orderService
      .getCarts(data)
      .then((result) => {
        commit(M_ORDER_GET_CART, { result })
      })
      .catch((result) => {
        commit(M_ORDER_GET_CART, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_ORDER_CREATE_CART]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    return orderService
      .createCart(data)
      .then((result) => {
        commit(M_ORDER_CREATE_CART, { result })
      })
      .catch((result) => {
        commit(M_ORDER_CREATE_CART, { result })
        const _buttons = [
          {
            title: localeHelper.getMessage('action.ok'),
            type: 'default',
            action: () => {
              dispatch(SHARED_CLOSE_DIALOG, {}, { root: true })
            }
          }
        ]
        dispatch(
          SHARED_SHOW_DIALOG,
          {
            messageTitle: localeHelper.getMessage('label.fail'),
            messages: [localeHelper.getErrorMessage(result.code)],
            messageErrorCode: result.code,
            buttons: _buttons
          },
          { root: true }
        )
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_ORDER_ADD_CART_ITEM]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    return orderService
      .addCartItem(data)
      .then((result) => {
        commit(M_ORDER_ADD_CART_ITEM, { result })
      })
      .catch((result) => {
        commit(M_ORDER_ADD_CART_ITEM, { result })
        const _buttons = [
          {
            title: localeHelper.getMessage('action.ok'),
            type: 'default',
            action: () => {
              dispatch(SHARED_CLOSE_DIALOG, {}, { root: true })
            }
          }
        ]
        dispatch(
          SHARED_SHOW_DIALOG,
          {
            messageTitle: localeHelper.getMessage('label.fail'),
            messages: [localeHelper.getErrorMessage(result.code)],
            messageErrorCode: result.code,
            buttons: _buttons
          },
          { root: true }
        )
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_ORDER_UPDATE_CART_ITEM]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    return orderService
      .updateCartItem(data)
      .then((result) => {
        commit(M_ORDER_UPDATE_CART_ITEM, { result })
      })
      .catch((result) => {
        commit(M_ORDER_UPDATE_CART_ITEM, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  },
  [A_ORDER_REMOVE_CART_ITEM]({ commit, dispatch }, { data }) {
    sharedHelper.loading(dispatch)
    return orderService
      .removeCartItem(data)
      .then((result) => {
        commit(M_ORDER_REMOVE_CART_ITEM, { result })
      })
      .catch((result) => {
        commit(M_ORDER_REMOVE_CART_ITEM, { result })
      })
      .finally(() => {
        sharedHelper.unloading(dispatch)
      })
  }
}

const mutations = {
  [M_ORDER_REFUND_ORDER](state, { result }) {
    state.refundOrderResponse = {
      action: result.success ? 'refundOrder' : 'refundOrderDecline',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_ORDER_RESET_REFUND_ORDER_STORE](state) {
    state.refundOrderResponse = {
      action: 'refundOrder',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_ORDER_CANCEL_ORDER](state, { result }) {
    state.cancelOrderResponse = {
      action: 'cancelOrder',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_ORDER_RESET_CANCEL_ORDER_STORE](state) {
    state.cancelOrderResponse = {
      action: 'cancelOrder',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_ORDER_VOID_ORDER](state, { result }) {
    state.voidOrderResponse = {
      action: result.success ? 'voidOrder' : 'voidOrderDecline',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_ORDER_RESET_VOID_ORDER_STORE](state) {
    state.voidOrderResponse = {
      action: 'voidOrder',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_ORDER_NORMAL_ORDER](state, { result }) {
    state.normalOrderResponse = {
      action: 'normalOrder',
      complete: true,
      success: result.success,
      code: result.code
    }

    if (result.success) {
      let d = result.data
      state.order = d
    }
  },
  [M_ORDER_RESET_NORMAL_ORDER_STORE](state) {
    state.normalOrderResponse = {
      action: 'normalOrder',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_ORDER_PRE_ORDER](state, { result }) {
    state.preOrderResponse = {
      action: 'preOrder',
      complete: true,
      success: result.success,
      code: result.code
    }

    if (result.success) {
      let d = result.data
      state.order = d
    }
  },
  [M_ORDER_RESET_PRE_ORDER_STORE](state) {
    state.preOrderResponse = {
      action: 'preOrder',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_ORDER_GET_HISTORY](state, { result }) {
    if (result.success) {
      let pagination = result.pagination

      state.orderHistory.pagination = {
        pageNumber: pagination.current_page,
        pageSize: Number(pagination.per_page),
        lastPage: pagination.last_page,
        tableRowsCount: pagination.total
      }

      state.orderHistory.data = result.data.map((o) => {
        return {
          createdBy: o.created_by,
          createdDateTime: o.created_date_time,
          currency: o.currency,
          deliveryMethodName: o.delivery_methods_name,
          deliveryMethodUuid: o.delivery_methods_uuid,
          depositAmount: o.deposit_amount,
          discountCode: o.discount_code,
          discountAmount: o.discount_amount,
          discountRemark: o.discount_remark,
          drawerUuid: o.drawer_uuid,
          email: o.email,
          emailBillingAddress: o.email_billing_address,
          emailCustomerName: o.email_customer_name,
          emailPhoneNumber: o.email_phone_number,
          emailPostageAddress: o.email_postage_address,
          emailPostageCustomerName: o.email_postage_customer_name,
          emailPostagePhoneNumber: o.email_postage_phone_number,
          emailRecipient: o.email_recipient,
          orderItems: o.order_items,
          outletName: o.outlet_name,
          outletUuid: o.outlet_uuid,
          orderReferenceNumber: o.reference_number,
          platform: o.platform,
          remark: o.remark,
          shippingFee: o.shipping_fee,
          purchasePrice: o.purchase_price,
          totalPrice: o.total_price,
          trackingNumber: o.tracking_number,
          courierName: o.courier_name,
          updatedBy: o.updated_by,
          updatedDateTime: o.updated_date_time,
          uuid: o.uuid,
          receiptPath: o.receipt_file_path,
          orderStatus: o.order_statuses_name.toLowerCase(),
          orderStatusHistory: o.order_status_histories,
          paymentInfo: o.order_payments,
          transactionType: o.transaction_type_name,
          surchargePercentage: o.surcharge_percentage,
          surchargePrice: o.surcharge_price,
          surchargeRemark: o.surcharge_remark,
          markUpPrice: o.mark_up_price,
          markUpRemark: o.mark_up_remark,
          markUpPercentage: o.mark_up_percentage
        }
      })
    }
  },
  [M_ORDER_GET_HISTORY_RESET](state) {
    state.orderHistory.pagination = {}

    state.orderHistory.data = {}
  },
  [M_ORDER_GET_CANCELLATIONS_HISTORY](state, { result }) {
    if (result.success) {
      let pagination = result.pagination

      state.orderHistory.pagination = {
        pageNumber: pagination.current_page,
        pageSize: Number(pagination.per_page),
        lastPage: pagination.last_page,
        tableRowsCount: pagination.total
      }

      state.orderHistory.data = result.data.map((o) => {
        return {
          createdBy: o.created_by,
          createdDateTime: o.created_date_time,
          currency: o.currency,
          deliveryMethodName: o.delivery_methods_name,
          deliveryMethodUuid: o.delivery_methods_uuid,
          depositAmount: o.deposit_amount,
          discountCode: o.discount_code,
          discountAmount: o.discount_amount,
          discountRemark: o.discount_remark,
          drawerUuid: o.drawer_uuid,
          email: o.email,
          emailBillingAddress: o.email_billing_address,
          emailCustomerName: o.email_customer_name,
          emailPhoneNumber: o.email_phone_number,
          emailPostageAddress: o.email_postage_address,
          emailPostageCustomerName: o.email_postage_customer_name,
          emailPostagePhoneNumber: o.email_postage_phone_number,
          emailRecipient: o.email_recipient,
          isApproved: o.is_approved,
          orderItems: o.cancellation_items,
          outletName: o.outlet_name,
          outletUuid: o.outlet_uuid,
          cancellationReferenceNumber: o.reference_number,
          orderReferenceNumber: o.order_reference_number,
          platform: o.platform,
          remark: o.remark,
          shippingFee: o.shipping_fee,
          totalPrice: o.total_price ?? o.refund_amount,
          refundAmount: o.refunded_price,
          trackingNumber: o.tracking_number,
          courierName: o.courier_name,
          updatedBy: o.updated_by,
          updatedDateTime: o.updated_date_time,
          uuid: o.uuid,
          orderStatus: o.order_statuses_name.toLowerCase(),
          orderStatusHistory: o.order_status_histories ?? [],
          paymentInfo: o.cancellation_payments,
          transactionType: o.transaction_type_name,
          surchargePercentage: o.surcharge_percentage,
          surchargePrice: o.surcharge_price,
          surchargeRemark: o.surcharge_remark,
          markUpPrice: o.mark_up_price,
          markUpRemark: o.mark_up_remark,
          markUpPercentage: o.mark_up_percentage
        }
      })
    }
  },
  [M_ORDER_GET_ORDER_INFO](state, { result }) {
    if (result.success) {
      let o = result.data
      state.order = {
        createdBy: o.created_by,
        createdDateTime: o.created_date_time,
        currency: o.currency,
        deliveryMethodName: o.delivery_methods_name,
        deliveryMethodUuid: o.delivery_methods_uuid,
        depositAmount: o.deposit_amount,
        discountCode: o.discount_code,
        discountAmount: o.discount_amount,
        discountRemark: o.discount_remark,
        drawerUuid: o.drawer_uuid,
        email: o.email,
        emailBillingAddress: o.email_billing_address,
        emailCustomerName: o.email_customer_name,
        emailPhoneNumber: o.email_phone_number,
        emailPostageAddress: o.email_postage_address,
        emailPostageCustomerName: o.email_postage_customer_name,
        emailPostagePhoneNumber: o.email_postage_phone_number,
        emailRecipient: o.email_recipient,
        orderItems: o.order_items,
        outletName: o.outlet_name,
        outletUuid: o.outlet_uuid,
        referenceNumber: o.reference_number,
        remark: o.remark,
        shippingFee: o.shipping_fee,
        totalPrice: o.total_price,
        purchasePrice: o.purchase_price,
        trackingNumber: o.tracking_number,
        courierName: o.courier_name,
        updatedBy: o.updated_by,
        updatedDateTime: o.updated_date_time,
        uuid: o.uuid,
        orderStatus: o.order_statuses_name.toLowerCase(),
        transactionType: o.transaction_type_name,
        linkReferenceNumber: o.linkage_ref_number,
        surchargePercentage: o.surcharge_percentage,
        surchargePrice: o.surcharge_price,
        surchargeRemark: o.surcharge_remark
      }
    }
  },
  [M_ORDER_UPDATE_STATUS](state, { result, data }) {
    state.updateOrderStatusResponse = {
      action: data.action,
      complete: true,
      success: result.success,
      code: result.code
    }

    if (result.success) {
      let o = result.data
      state.order = {
        createdBy: o.created_by,
        createdDateTime: o.created_date_time,
        currency: o.currency,
        deliveryMethodName: o.delivery_methods_name,
        deliveryMethodUuid: o.delivery_methods_uuid,
        depositAmount: o.deposit_amount,
        discountCode: o.discount_code,
        discountAmount: o.discount_amount,
        discountRemark: o.discount_remark,
        drawerUuid: o.drawer_uuid,
        email: o.email,
        emailBillingAddress: o.email_billing_address,
        emailCustomerName: o.email_customer_name,
        emailPhoneNumber: o.email_phone_number,
        emailPostageAddress: o.email_postage_address,
        emailPostageCustomerName: o.email_postage_customer_name,
        emailPostagePhoneNumber: o.email_postage_phone_number,
        emailRecipient: o.email_recipient,
        orderItems: o.order_items,
        outletName: o.outlet_name,
        outletUuid: o.outlet_uuid,
        referenceNumber: o.reference_number,
        remark: o.remark,
        shippingFee: o.shipping_fee,
        purchasePrice: o.purchase_price,
        totalPrice: o.total_price,
        trackingNumber: o.tracking_number,
        courierName: o.courier_name,
        updatedBy: o.updated_by,
        updatedDateTime: o.updated_date_time,
        uuid: o.uuid,
        orderStatus: o.order_statuses_name.toLowerCase(),
        transactionType: o.transaction_type_name,
        linkReferenceNumber: o.linkage_ref_number,
        surchargePercentage: o.surcharge_percentage,
        surchargePrice: o.surcharge_price,
        surchargeRemark: o.surcharge_remark
      }
    }
  },
  [M_RESET_ORDER_UPDATE_STATUS_STORE](state) {
    state.updateOrderStatusResponse = {
      action: 'updateOrderStatus',
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_ORDER_CREATE_PAYMENT_ORDER](state, { result }) {
    state.createOrderPaymentResponse = {
      action: 'createOrderPayment',
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_ORDER_RESET_CART](state) {
    state.cart = { items: [] }
    state.cartResponse = {
      complete: false,
      success: false,
      code: 0
    }
  },
  [M_ORDER_GET_CART](state, { result }) {
    state.cartResponse = {
      complete: true,
      success: result.success,
      code: result.code
    }
    const latestCart = result.data.find((c) => c.cart_status_name.toLowerCase() === 'pending')
    state.cart = {
      uuid: latestCart?.uuid,
      currency: latestCart?.currency,
      platform: latestCart?.platform,
      ipAddress: latestCart?.ip_address,
      items: latestCart?.items.map(parseCartItemsWithUuid(latestCart.uuid)) ?? [],
      customerName: latestCart?.customer_name,
      email: latestCart?.email,
      phoneNumber: latestCart?.phone_number,
      remark: latestCart?.remark,
      discountCode: latestCart?.discount_code,
      discountPercentage: latestCart?.discount_percentage,
      depositAmount: latestCart?.deposit_amount,
      discountAmount: latestCart?.discount_amount,
      discountRemark: latestCart?.discount_remark,
      markUpPrice: latestCart?.mark_up_price,
      markUpPercentage: latestCart?.mark_up_percentage,
      markUpPrice_remark: latestCart?.mark_up_price_remark,
      cartStatusUuid: latestCart?.cart_status_uuid,
      cartStatusName: latestCart?.cart_status_name,
      createdBy: latestCart?.created_by,
      createdDateTime: latestCart?.created_date_time,
      updatedBy: latestCart?.updated_by,
      updatedDateTime: latestCart?.updated_date_time,
      surchargePercentage: latestCart?.surcharge_percentage,
      surchargePrice: latestCart?.surcharge_price,
      surchargeRemark: latestCart?.surcharge_remark
    }
  },
  [M_ORDER_CREATE_CART](state, { result }) {
    state.createCartResponse = {
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_ORDER_ADD_CART_ITEM](state, { result }) {
    state.addCartItemResponse = {
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_ORDER_UPDATE_CART_ITEM](state, { result }) {
    state.updateCartItemResponse = {
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_ORDER_REMOVE_CART_ITEM](state, { result }) {
    state.removeCartItemResponse = {
      complete: true,
      success: result.success,
      code: result.code
    }
  },
  [M_ORDER_SET_CART_ITEM_PRICES](state, { cartProductVariantInfo }) {
    const flattenedProductVariants = cartProductVariantInfo.flatMap(({ variants, ...prodAttrs }) => variants.map((variant) => ({ ...variant, ...prodAttrs })))
    state.cartWithPrices = {
      ...state.cart,
      items: state.cart.items.map((i) => {
        if (flattenedProductVariants.find((variant) => variant.display_sku === i.productDisplaySku) != undefined) {
          const matchingVariant = flattenedProductVariants.find((variant) => variant.display_sku === i.productDisplaySku)
          const insuranceType = matchingVariant.types.find((t) => ['phones', 'tablets'].includes(t.name.toLowerCase()))?.name
          let insurance = null

          if (insuranceType) {
            let tierInsurance = EXTERNAL_PRODUCT.INSURANCE[insuranceType.toUpperCase()].tier.find(
              (x) => x.minCoverPrice <= matchingVariant.discounted_price && x.maxCoverPrice >= matchingVariant.discounted_price
            )
            if (tierInsurance != undefined) {
              insurance = {
                name: tierInsurance.name,
                type: 'Insurance',
                quantity: i.insurance.quantity,
                sku: tierInsurance.sku,
                display_sku: tierInsurance.display_sku,
                unit_price: tierInsurance.unit_price,
                remark: `${matchingVariant.name}\n${matchingVariant.options
                  .map((o) => {
                    return o.value
                  })
                  .join('/')}\n${matchingVariant.display_sku}`
              }
            }
          }

          return {
            ...i,
            discountedPrice: matchingVariant.discounted_price,
            discountedRate: Math.floor(matchingVariant.discount_rate * 100),
            unitPrice: matchingVariant.selling_price,
            variation: matchingVariant.options
              .map((o) => {
                return o.value
              })
              .join('/'),
            insuranceType: insuranceType,
            insurance: insurance,
            requiredInsurance: i.insurance.quantity > 0
          }
        }
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

const parseCartItemsWithUuid = (cartUuid) => (c) => ({
  uuid: c.uuid,
  cartUuid: cartUuid,
  productBrandName: c.product_brand_name,
  productName: c.product_name,
  productVariants: c.product_variants,
  group: c.group,
  productDisplaySku: c.product_display_sku,
  quantity: c.quantity,
  insurance: c.services.find((s) => s.type.toLowerCase() === 'insurance') || { quantity: 0 },
  depositAmount: c.deposit_amount,
  discountCode: c.discount_code,
  discountPercentage: c.discount_percentage,
  discountAmount: c.discount_amount,
  discountRemark: c.discount_remark,
  markUpPrice: c.mark_up_price,
  markUpPercentage: c.mark_up_percentage,
  markUpPriceRemark: c.mark_up_price_remark,
  remark: c.remark,
  cartStatusUuid: c.cart_status_uuid,
  cartStatusName: c.cart_status_name
})
