var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "12",
        height: "13",
        viewBox: "0 0 12 13",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_4312_918)" } }, [
        _c("g", { attrs: { "clip-path": "url(#clip1_4312_918)" } }, [
          _c("path", {
            attrs: {
              d: "M10.5 5.5C10.5 9 6 12 6 12C6 12 1.5 9 1.5 5.5C1.5 4.30653 1.97411 3.16193 2.81802 2.31802C3.66193 1.47411 4.80653 1 6 1C7.19347 1 8.33807 1.47411 9.18198 2.31802C10.0259 3.16193 10.5 4.30653 10.5 5.5Z",
              stroke: "inherit",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
          _c("path", {
            attrs: {
              d: "M6 7C6.82843 7 7.5 6.32843 7.5 5.5C7.5 4.67157 6.82843 4 6 4C5.17157 4 4.5 4.67157 4.5 5.5C4.5 6.32843 5.17157 7 6 7Z",
              stroke: "inherit",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
        ]),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_4312_918" } }, [
          _c("rect", {
            attrs: {
              width: "12",
              height: "12",
              fill: "white",
              transform: "translate(0 0.5)",
            },
          }),
        ]),
        _c("clipPath", { attrs: { id: "clip1_4312_918" } }, [
          _c("rect", {
            attrs: {
              width: "12",
              height: "12",
              fill: "white",
              transform: "translate(0 0.5)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }