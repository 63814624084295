var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-overlay",
    {
      attrs: { opacity: "0.7", "z-index": "300", value: _vm.loadingIndex > 0 },
    },
    [
      _c("v-progress-circular", {
        attrs: {
          indeterminate: "",
          size: "100",
          width: "10",
          color: "unbox_primary",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }