import { render, staticRenderFns } from "./arrowLeft.vue?vue&type=template&id=56240808&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins/workspace/e-commerce-development/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56240808')) {
      api.createRecord('56240808', component.options)
    } else {
      api.reload('56240808', component.options)
    }
    module.hot.accept("./arrowLeft.vue?vue&type=template&id=56240808&", function () {
      api.rerender('56240808', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/assets/icons/arrowLeft.vue"
export default component.exports