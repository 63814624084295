var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "13",
        height: "12",
        viewBox: "0 0 13 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6.55984 8.94075L8.92422 10.4385C9.22647 10.6298 9.60147 10.3451 9.51184 9.99225L8.82897 7.30538C8.80968 7.23054 8.8119 7.15177 8.83538 7.07813C8.85887 7.0045 8.90266 6.93899 8.96172 6.88913L11.082 5.12475C11.3602 4.893 11.217 4.43063 10.8588 4.40738L8.09022 4.22738C8.01567 4.22209 7.94417 4.19574 7.88402 4.15139C7.82387 4.10704 7.77756 4.04652 7.75047 3.97688L6.71772 1.37663C6.68959 1.30273 6.63968 1.23913 6.57458 1.19425C6.50948 1.14937 6.43228 1.12534 6.35322 1.12534C6.27415 1.12534 6.19695 1.14937 6.13185 1.19425C6.06676 1.23913 6.01684 1.30273 5.98872 1.37663L4.95597 3.97688C4.92893 4.04659 4.88264 4.10719 4.82249 4.15161C4.76234 4.19603 4.6908 4.22243 4.61622 4.22775L1.84759 4.40775C1.48984 4.43063 1.34584 4.893 1.62447 5.12475L3.74472 6.8895C3.80371 6.93933 3.84745 7.00478 3.87094 7.07834C3.89442 7.1519 3.89668 7.23059 3.87747 7.30538L3.24447 9.79725C3.13684 10.2206 3.58722 10.5623 3.94947 10.3324L6.14697 8.94075C6.20873 8.90149 6.2804 8.88063 6.35359 8.88063C6.42678 8.88063 6.49845 8.90149 6.56022 8.94075H6.55984Z",
          fill: "#FCD34D",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }