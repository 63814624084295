var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            "return-value": _vm.value,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "auto",
          },
          on: {
            "update:returnValue": function ($event) {
              _vm.value = $event
            },
            "update:return-value": function ($event) {
              _vm.value = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-body-2 font-weight-bold unbox_secondary--text mb-2",
                    },
                    [_vm._v(_vm._s(_vm.label))]
                  ),
                  _c(
                    "v-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "rounded-lg",
                          attrs: {
                            value: _vm.value,
                            placeholder: _vm.placeholder,
                            outlined: "",
                            "background-color": "white",
                            readonly: "",
                          },
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _c("v-date-picker", {
            attrs: { value: _vm.value },
            on: { input: _vm.input },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }