var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "178",
        height: "156",
        viewBox: "0 0 178 156",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("circle", {
        attrs: {
          cx: "58.9999",
          cy: "150.75",
          r: "4",
          stroke: "#34D399",
          "stroke-width": "2",
        },
      }),
      _c("circle", {
        attrs: {
          cx: "48.9999",
          cy: "5",
          r: "4",
          stroke: "#34D399",
          "stroke-width": "2",
        },
      }),
      _c("circle", {
        attrs: { cx: "46.9999", cy: "117", r: "2", fill: "#34D399" },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M168.054 11.6787L166.157 15.8904L164.769 15.2654L166.666 11.0538L162.455 9.15682L163.08 7.76938L167.291 9.66636L169.188 5.45473L170.576 6.07965L168.679 10.2913L172.89 12.1883L172.265 13.5757L168.054 11.6787V11.6787Z",
          stroke: "#34D399",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M127.282 125.605L125.385 129.817L123.998 129.192L125.895 124.98L121.683 123.083L122.308 121.696L126.52 123.593L128.417 119.381L129.804 120.006L127.907 124.218L132.119 126.115L131.494 127.502L127.282 125.605Z",
          fill: "#34D399",
          stroke: "#34D399",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M76.3039 49.3259L73.0377 52.5921L71.9617 51.5161L75.2279 48.2499L71.9617 44.9837L73.0377 43.9077L76.3039 47.1739L79.5701 43.9077L80.6461 44.9837L77.3799 48.2499L80.6461 51.5161L79.5701 52.5921L76.3039 49.3259Z",
          fill: "#6EE7B7",
          stroke: "#6EE7B7",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M104.696 49.3259L101.43 52.5921L100.354 51.5161L103.62 48.2499L100.354 44.9837L101.43 43.9077L104.696 47.1739L107.962 43.9077L109.038 44.9837L105.772 48.2499L109.038 51.5161L107.962 52.5921L104.696 49.3259Z",
          fill: "#6EE7B7",
          stroke: "#6EE7B7",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M4.37258 71.3829L1.30573 71.5536L1.24951 70.5432L4.31636 70.3726L4.14569 67.3057L5.15601 67.2495L5.32667 70.3164L8.39352 70.1457L8.44974 71.156L5.38289 71.3267L5.55355 74.3935L4.54324 74.4497L4.37258 71.3829Z",
          fill: "#D5DDE0",
          stroke: "#D5DDE0",
          "stroke-width": "0.664972",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M151.017 41.0246L147.42 41.9883L147.103 40.8035L150.699 39.8399L149.736 36.2435L150.92 35.9261L151.884 39.5224L155.48 38.5588L155.798 39.7435L152.202 40.7072L153.165 44.3035L151.98 44.621L151.017 41.0246Z",
          fill: "#D5DDE0",
          stroke: "#D5DDE0",
          "stroke-width": "0.664972",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M172.568 111.456L169.501 111.627L169.445 110.616L172.511 110.446L172.341 107.379L173.351 107.323L173.522 110.39L176.589 110.219L176.645 111.229L173.578 111.4L173.749 114.467L172.738 114.523L172.568 111.456Z",
          fill: "#D5DDE0",
          stroke: "#D5DDE0",
          "stroke-width": "0.664972",
        },
      }),
      _c("path", {
        attrs: {
          d: "M90.4998 107.833C115.813 107.833 136.333 87.313 136.333 62C136.333 36.6869 115.813 16.1666 90.4998 16.1666C65.1868 16.1666 44.6665 36.6869 44.6665 62C44.6665 87.313 65.1868 107.833 90.4998 107.833Z",
          stroke: "#6EE7B7",
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M72.1665 75.75H108.833",
          stroke: "#6EE7B7",
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }