var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "21",
        height: "20",
        viewBox: "0 0 21 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M9.693 5.82L4.55015 10.9629C4.39586 11.1171 4.30157 11.3314 4.30157 11.5714C4.30157 12.0429 4.68729 12.4286 5.15872 12.4286C5.39872 12.4286 5.613 12.3343 5.76729 12.18L10.3016 7.63714L14.8359 12.1714C14.9901 12.3343 15.2044 12.4286 15.4444 12.4286C15.9159 12.4286 16.3016 12.0429 16.3016 11.5714C16.3016 11.3314 16.2073 11.1171 16.053 10.9629L10.9101 5.82C10.7559 5.66571 10.5416 5.57143 10.3016 5.57143C10.0616 5.57143 9.84729 5.66571 9.693 5.82Z",
          fill: "#C7A87E",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }