var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "23",
        height: "23",
        viewBox: "0 0 23 23",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_4284_2185)" } }, [
        _c("path", {
          attrs: {
            d: "M17.361 19.9597C18.6267 19.9597 19.6527 18.9337 19.6527 17.668C19.6527 16.4024 18.6267 15.3763 17.361 15.3763C16.0954 15.3763 15.0693 16.4024 15.0693 17.668C15.0693 18.9337 16.0954 19.9597 17.361 19.9597Z",
            stroke: "inherit",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M5.44401 19.9597C6.70966 19.9597 7.73568 18.9337 7.73568 17.668C7.73568 16.4024 6.70966 15.3763 5.44401 15.3763C4.17836 15.3763 3.15234 16.4024 3.15234 17.668C3.15234 18.9337 4.17836 19.9597 5.44401 19.9597Z",
            stroke: "inherit",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M15.0693 8.04309H18.736L21.486 10.7931V15.3764H15.0693V8.04309Z",
            stroke: "inherit",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M15.0693 3.45972H1.31934V15.3764H15.0693V3.45972Z",
            stroke: "inherit",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_4284_2185" } }, [
          _c("rect", {
            attrs: {
              width: "22",
              height: "22",
              fill: "white",
              transform: "translate(0.402344 0.709717)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }