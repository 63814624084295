var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "text-body-2 font-weight-bold unbox_secondary--text mb-2",
        },
        [_vm._v(_vm._s(_vm.label))]
      ),
      _vm.type == "text" || _vm.type == "password"
        ? _c("v-text-field", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: _vm.mask,
                expression: "mask",
              },
            ],
            staticClass: "rounded-lg",
            attrs: {
              value: _vm.value,
              placeholder: _vm.placeholder,
              outlined: "",
              "background-color": "white",
              rules: _vm.readonly
                ? []
                : _vm.isOptional
                ? _vm.value.length > 0
                  ? _vm.rules
                  : []
                : _vm.rules,
              type:
                _vm.type == "password"
                  ? _vm.showPassword
                    ? "text"
                    : "password"
                  : _vm.type,
              readonly: _vm.readonly,
              disabled: _vm.readonly,
              prefix: _vm.prefix,
            },
            on: {
              "click:append": function ($event) {
                _vm.type == "password"
                  ? (_vm.showPassword = !_vm.showPassword)
                  : (_vm.showPassword = !_vm.showPassword)
              },
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "space", 32, $event.key, [
                    " ",
                    "Spacebar",
                  ])
                ) {
                  return null
                }
                return _vm.preventLeadingSpace.apply(null, arguments)
              },
              input: _vm.input,
              change: _vm.change,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "mt-n4" },
                        [
                          _vm.type == "password"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-4",
                                      on: {
                                        click: function ($event) {
                                          _vm.type == "password"
                                            ? (_vm.showPassword =
                                                !_vm.showPassword)
                                            : (_vm.showPassword =
                                                !_vm.showPassword)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showPassword
                                            ? "mdi-eye"
                                            : "mdi-eye-off"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !!_vm.appendButton
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "mt-2 white--text",
                                  attrs: {
                                    color: "#97A3B6",
                                    disabled: _vm.appendButton.disabled,
                                  },
                                  on: { click: _vm.appendButton.onClick },
                                },
                                [_vm._v(_vm._s(_vm.appendButton.title))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3694013000
            ),
          })
        : _vm._e(),
      _vm.type == "select"
        ? _c("v-select", {
            staticClass: "rounded-lg",
            attrs: {
              value: _vm.value,
              items: _vm.items,
              placeholder: _vm.placeholder,
              outlined: "",
              "background-color": "white",
              readonly: _vm.readonly || _vm.items.length <= 0,
              disabled: _vm.readonly || _vm.items.length <= 0,
              rules: _vm.readonly
                ? []
                : _vm.isOptional
                ? _vm.value.length > 0
                  ? _vm.rules
                  : []
                : _vm.rules,
              "item-text": _vm.itemText,
              "item-value": _vm.itemValue,
            },
            on: { input: _vm.input, change: _vm.change },
          })
        : _vm._e(),
      _vm.type == "textarea"
        ? _c("v-textarea", {
            staticClass: "rounded-lg",
            attrs: {
              value: _vm.value,
              rows: _vm.rows,
              placeholder: _vm.placeholder,
              outlined: "",
              "background-color": "white",
              rules: _vm.readonly
                ? []
                : _vm.isOptional
                ? _vm.value.length > 0
                  ? _vm.rules
                  : []
                : _vm.rules,
              readonly: _vm.readonly,
              disabled: _vm.readonly,
            },
            on: { input: _vm.input, change: _vm.change },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }