var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "45",
        height: "32",
        viewBox: "0 0 45 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "0.5",
          y: "0.5",
          width: "44",
          height: "30.7647",
          rx: "4.79412",
          fill: "#1A1A1A",
          stroke: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M14.2284 20.9889H11.5022L9.45797 12.9605C9.36094 12.5912 9.15492 12.2647 8.85187 12.1108C8.09557 11.7241 7.26218 11.4164 6.35303 11.2612V10.9521H10.7446C11.3507 10.9521 11.8053 11.4164 11.881 11.9556L12.9417 17.7467L15.6665 10.9521H18.3169L14.2284 20.9889ZM19.8322 20.9889H17.2576L19.3776 10.9521H21.9522L19.8322 20.9889ZM25.2832 13.7327C25.3589 13.1921 25.8135 12.883 26.3438 12.883C27.1772 12.8054 28.085 12.9606 28.8427 13.346L29.2973 11.185C28.5396 10.8759 27.7062 10.7207 26.9499 10.7207C24.4511 10.7207 22.6328 12.1109 22.6328 14.0404C22.6328 15.5083 23.9208 16.279 24.8299 16.7433C25.8135 17.2063 26.1923 17.5154 26.1165 17.9783C26.1165 18.6728 25.3589 18.9819 24.6026 18.9819C23.6935 18.9819 22.7843 18.7504 21.9523 18.3637L21.4977 20.526C22.4068 20.9114 23.3904 21.0666 24.2996 21.0666C27.1015 21.1429 28.8427 19.7539 28.8427 17.6693C28.8427 15.044 25.2832 14.8901 25.2832 13.7327ZM37.853 20.9889L35.8087 10.9521H33.6129C33.1583 10.9521 32.7038 11.2612 32.5522 11.7241L28.7668 20.9889H31.4171L31.9461 19.5223H35.2026L35.5056 20.9889H37.853ZM33.9918 13.655L34.7481 17.4377H32.6281L33.9918 13.655Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }