<template>
  <div id="unboxFooter">
    <v-card class="pt-10 pb-3" elevation="0" color="unbox_black" tile>
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <v-row no-gutters>
            <v-col cols="12" lg="4" class="text-sm-left text-center">
              <div class="mb-5">
                <img src="/static/images/logo/logo_white_base.png" alt="Logo UNBOX Malaysia" title="Logo UNBOX Malaysia" />
              </div>
              <div class="text-body-2 unbox_secondary--text mb-5 text-uppercase">{{ $t(`label.slogan`) }}</div>
              <div class="text-body-2">
                <span class="white--text d-block">{{ $t(`label.connectWithUs`) }}</span>
                <div>
                  <v-btn class="mx-1" icon elevation="0" color="unbox_black" @click="goToExternalLink('https://api.whatsapp.com/send/?phone=601155550000&text&app_absent=0')">
                    <img class="contactUs" src="/static/images/footerContactUs/whatsapp-icon.png" alt="WhatsApp" title="WhatsApp | Contact Us" />
                  </v-btn>
                  <v-btn class="mx-1" icon elevation="0" color="unbox_black" @click="goToExternalLink('https://www.facebook.com/unboxmalaysia/')">
                    <img class="contactUs" src="/static/images/footerContactUs/facebook-icon.png" alt="Facebook" title="Facebook | Contact Us" />
                  </v-btn>
                  <v-btn class="mx-1" icon elevation="0" @click="goToExternalLink('https://www.instagram.com/unboxmalaysia/')">
                    <img class="contactUs" src="/static/images/footerContactUs/instagram-icon.png" alt="Instagram" title="Instagram | Contact Us" />
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" lg="8" class="mt-16 mt-lg-0 text-sm-left text-center">
              <v-row>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <div class="unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5">{{ $t(`label.customerCare`) }}</div>
                  <div>
                    <div class="unbox_secondary--text text-body-2 mb-3 cursor-pointer" @click="() => goToStaticRoute(routeName.REFUND_POLICY)">{{ $t(`label.refundPolicy`) }}</div>
                    <div class="unbox_secondary--text text-body-2 mb-3 cursor-pointer" @click="() => goToStaticRoute(routeName.PRIVACY_POLICY)">{{ $t(`label.privacyPolicy`) }}</div>
                    <div class="unbox_secondary--text text-body-2 mb-3 cursor-pointer" @click="() => goToStaticRoute(routeName.TERMS_AND_CONDITIONS)">{{ $t(`label.termsAndConditions`) }}</div>
                    <div class="unbox_secondary--text text-body-2 mb-3 cursor-pointer" @click="() => goToStaticRoute(routeName.PROTECTION_PLAN)">
                      {{ $t(`label.unboxCareTermsAndConditions`) }}
                    </div>
                    <div class="unbox_secondary--text text-body-2 mb-3 cursor-pointer" @click="() => goToStaticRoute(routeName.CONTACT_US)">{{ $t(`label.contactUs`) }}</div>
                    <div class="unbox_secondary--text text-body-2 mb-3 cursor-pointer" @click="() => goToStaticRoute(routeName.LOCATE_US)">{{ $t(`label.findAStore`) }}</div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <div class="unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5">{{ $t(`label.aboutUs`) }}</div>
                  <div>
                    <div class="unbox_secondary--text text-body-2 mb-3 cursor-pointer" @click="() => goToStaticRoute(routeName.ABOUT_US)">{{ $t(`label.aboutUs`) }}</div>
                    <div class="unbox_secondary--text text-body-2 mb-3 cursor-pointer" @click="() => goToStaticRoute(routeName.CAREER)">{{ $t(`label.career`) }}</div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <div class="unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5">{{ $t(`label.getTheApp`) }}</div>
                  <div>
                    <div class="text-center app-qr-image mb-2 mx-sm-0 mx-auto">
                      <qr-code :text="downloadLink" :size="120"></qr-code>
                    </div>
                    <div class="unbox_secondary--text text-body-2 mb-3">{{ $t(`label.scanQRorClickBelow`) }}</div>
                    <div class="text-center playstore-icon mb-2 mx-sm-0 mx-auto cursor-pointer" @click="openPlayStoreLink">
                      <img src="/static/images/store/playstore.png" alt="Google Play Store" title="Google Play Store" />
                    </div>
                    <div class="text-center playstore-icon mb-2 mx-sm-0 mx-auto cursor-pointer" @click="openAppStoreLink">
                      <img src="/static/images/store/appstore.png" alt="App Store" title="App Store" />
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <div class="unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5">{{ $t(`label.weAlsoAccept`) }}</div>
                  <div>
                    <img class="footerPayment" src="/static/images/footerPayment/fpx.png" alt="FPX" title="FPX | Payment Method" />
                    <img class="footerPayment" src="/static/images/footerPayment/grabPay.png" alt="GrabPay" title="GrabPay | Payment Method" />
                    <img class="footerPayment" src="/static/images/footerPayment/tng.png" alt="Touch 'N Go E-wallet" title="Touch 'N Go E-wallet | Payment Method" />
                    <img class="footerPayment" src="/static/images/footerPayment/boostPay.png" alt="BoostPay" title="BoostPay | Payment Method" />
                  </div>
                  <div class="unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5">{{ $t(`label.availableInstallment`) }}</div>
                  <div>
                    <img class="footerPayment" src="/static/images/footerPayment/hsbc.png" alt="HSBC" title="HSBC | Payment Method" />
                    <img class="footerPayment" src="/static/images/footerPayment/cimb.png" alt="CIMB" title="CIMB | Payment Method" />
                    <img class="footerPayment" src="/static/images/footerPayment/affin.png" alt="Affin" title="Affin | Payment Method" />
                    <img class="footerPayment" src="/static/images/footerPayment/uob.png" alt="UOB" title="UOB | Payment Method" />
                  </div>
                  <div class="unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5">{{ $t(`label.buyNowPayLater`) }}</div>
                  <div>
                    <img class="footerPayment" src="/static/images/footerPayment/atome.png" alt="" />
                    <img class="footerPayment" src="/static/images/footerPayment/grab.png" alt="Grab" title="PayLater by Grab | Payment Method" />
                    <img class="footerPayment" src="/static/images/footerPayment/payLater.png" alt="" />
                    <img class="footerPayment" src="/static/images/footerPayment/split.png" alt="" />
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="my-4"></div>
            </v-col>
          </v-row>

          <v-row align="center" justify="space-between" class="py-4">
            <v-col cols="12" md="auto" lg="auto" order="12" order-md="1" order-lg="1" class="text-center text-lg-left py-4 py-lg-0">
              <div class="text-body-2 unbox_secondary--text">
                {{ $t(`label.copyright`) }} © {{ year }}, {{ $t(`label.unboxAsia`) }}.
                <span class="text-uppercase">{{ $t(`label.allRightsReserved`) }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import VueQRCodeComponent from 'vue-qrcode-component'
import { ROUTE_NAME } from '@/constants/route.constants'
import { storageHelper, sharedHelper } from '@/utils'
import { CACHE_KEY, SHARED } from '@/constants'
export default {
  name: 'AppFooter',
  components: {
    'qr-code': VueQRCodeComponent
  },
  data: () => ({
    downloadLink: `${window.location.origin}/app/download`,
    year: new Date().getFullYear(),
    routeName: ROUTE_NAME,
    storageProductType: storageHelper.getLocalStorage(`${CACHE_KEY.PRODUCT_TYPE}`)
  }),
  computed: {
    productTypes() {
      return this.$store.state.lookup.productTypes
    }
  },
  watch: {
    productTypes() {
      this.storageProductType = this.$store.state.lookup.productTypes
    }
  },
  methods: {
    openPlayStoreLink() {
      window.open('https://play.google.com/store/apps/details?id=com.keyboard.unbox.eshop', '_blank')
    },
    openAppStoreLink() {
      window.open('https://apps.apple.com/my/app/unbox-all-digital-needs/id1602781842', '_blank')
    },
    goToExternalLink(link) {
      window.open(link, '_blank')
    },
    goToStaticRoute(route) {
      this.$router.push({
        name: route
      })
    },
    goToShopByCategory(productType) {
      let shopSearchCriteria = sharedHelper.setShopSearchFilter('category', `${productType.text.toLowerCase().replace(/\s+/g, '_')}-${SHARED.PRODUCT_TYPE_PREFIX}${productType.id}`)

      this.$router.push({
        name: ROUTE_NAME.SHOP,
        query: shopSearchCriteria
      })
    }
  }
}
</script>
<style lang="scss">
.footerPayment {
  background-color: var(--v-light_gray-base);
  height: 55px;
  margin-left: 5px;
  border-radius: 4px;
}
.contactUs {
  height: 30px;
  margin-left: 5px;
}
.playstore-icon {
  width: auto;
  padding: 4px 15px 0px;
  background-color: black;
  border-radius: 15px;
  border: 2px solid #333333;
  max-width: 160px;
  img {
    width: 100%;
  }
}
.app-qr-image {
  width: auto;
  max-width: 150px;
  > div {
    border-radius: 10px;
    padding: 15px;
    background-color: white;
  }
}
</style>
