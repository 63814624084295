var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "21",
        height: "20",
        viewBox: "0 0 21 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M13.3149 8.39143L8.17207 3.24857C8.01779 3.09428 7.8035 3 7.5635 3C7.09207 3 6.70636 3.38571 6.70636 3.85714C6.70636 4.09714 6.80065 4.31142 6.95493 4.46571L11.4978 9L6.9635 13.5343C6.80064 13.6886 6.70636 13.9029 6.70636 14.1429C6.70636 14.6143 7.09207 15 7.5635 15C7.8035 15 8.01779 14.9057 8.17207 14.7514L13.3149 9.60857C13.4692 9.45428 13.5635 9.24 13.5635 9C13.5635 8.76 13.4692 8.54571 13.3149 8.39143Z",
          fill: "#C7A87E",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }