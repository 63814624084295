var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "40",
        height: "41",
        viewBox: "0 0 40 41",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_3998_2855)" } }, [
        _c("path", {
          attrs: {
            stroke: "inherit",
            d: "M38.3959 10.3661C38.3959 10.2142 38.3622 10.0622 38.2946 9.91867C38.1595 9.63163 37.8978 9.43745 37.6108 9.37836L20.4137 1.03728C20.1351 0.902205 19.8058 0.902205 19.5272 1.03728L2.16124 9.45434C1.82354 9.61474 1.60404 9.95244 1.58716 10.3323V10.3408C1.58716 10.3492 1.58716 10.3577 1.58716 10.3746V31.4974C1.58716 31.8857 1.80666 32.2403 2.16124 32.4092L19.5272 40.8262C19.5357 40.8262 19.5357 40.8262 19.5441 40.8347C19.5694 40.8431 19.5948 40.8516 19.6201 40.8684C19.6285 40.8684 19.637 40.8769 19.6539 40.8769C19.6792 40.8853 19.7045 40.8938 19.7298 40.9022C19.7383 40.9022 19.7467 40.9106 19.7552 40.9106C19.7805 40.9191 19.8143 40.9191 19.8396 40.9275C19.848 40.9275 19.8565 40.9275 19.8649 40.9275C19.8987 40.9275 19.9409 40.936 19.9747 40.936C20.0084 40.936 20.0506 40.936 20.0844 40.9275C20.0929 40.9275 20.1013 40.9275 20.1097 40.9275C20.1351 40.9275 20.1688 40.9191 20.1942 40.9106C20.2026 40.9106 20.211 40.9022 20.2195 40.9022C20.2448 40.8938 20.2701 40.8853 20.2955 40.8769C20.3039 40.8769 20.3124 40.8684 20.3292 40.8684C20.3546 40.86 20.3799 40.8516 20.4052 40.8347C20.4137 40.8347 20.4137 40.8347 20.4221 40.8262L37.8387 32.3838C38.1849 32.215 38.4128 31.8604 38.4128 31.4721V10.3914C38.3959 10.383 38.3959 10.3746 38.3959 10.3661ZM19.9662 3.0719L35.0274 10.3746L29.4808 13.0677L14.4196 5.76502L19.9662 3.0719ZM19.9662 17.6772L4.90501 10.3746L12.0979 6.88785L27.1591 14.1905L19.9662 17.6772ZM3.61333 12.0039L18.9531 19.4417V38.2935L3.61333 30.8558V12.0039ZM20.9793 38.2935V19.4417L28.1807 15.9465V20.8769C28.1807 21.4341 28.6365 21.89 29.1937 21.89C29.7509 21.89 30.2068 21.4341 30.2068 20.8769V14.9588L36.3698 11.9702V30.822L20.9793 38.2935Z",
            fill: "inherit",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_3998_2855" } }, [
          _c("rect", {
            attrs: {
              width: "40",
              height: "40",
              fill: "white",
              transform: "translate(3.05176e-05 0.935974)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }