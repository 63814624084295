var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "21",
        height: "20",
        viewBox: "0 0 21 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M10.5768 12.18L15.7197 7.03714C15.874 6.88286 15.9683 6.66857 15.9683 6.42857C15.9683 5.95714 15.5825 5.57143 15.1111 5.57143C14.8711 5.57143 14.6568 5.66571 14.5025 5.82L9.96826 10.3629L5.43398 5.82857C5.27969 5.66571 5.0654 5.57143 4.8254 5.57143C4.35398 5.57143 3.96826 5.95714 3.96826 6.42857C3.96826 6.66857 4.06255 6.88285 4.21683 7.03714L9.35969 12.18C9.51398 12.3343 9.72826 12.4286 9.96826 12.4286C10.2083 12.4286 10.4225 12.3343 10.5768 12.18Z",
          fill: "#C7A87E",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }