var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "21",
        height: "20",
        viewBox: "0 0 21 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M6.95497 9.60857L12.0978 14.7514C12.2521 14.9057 12.4664 15 12.7064 15C13.1778 15 13.5635 14.6143 13.5635 14.1429C13.5635 13.9029 13.4693 13.6886 13.315 13.5343L8.77211 9L13.3064 4.46571C13.4693 4.31142 13.5635 4.09714 13.5635 3.85714C13.5635 3.38571 13.1778 3 12.7064 3C12.4664 3 12.2521 3.09428 12.0978 3.24857L6.95497 8.39143C6.80068 8.54571 6.70639 8.76 6.70639 9C6.70639 9.24 6.80068 9.45428 6.95497 9.60857Z",
          fill: "#C7A87E",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }