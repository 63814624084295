var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "23",
        height: "23",
        viewBox: "0 0 23 23",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_4284_2180)" } }, [
        _c("path", {
          attrs: {
            d: "M15.4485 14.4602L18.225 11.6214C18.4953 11.4134 19.1671 11.0786 19.6912 11.403C19.7941 11.4667 19.8818 11.5283 19.9564 11.5868C20.296 11.853 20.4047 12.2062 20.4352 12.6367V12.6367C20.4713 13.1465 20.1865 13.6991 19.8732 14.1029L18.3292 16.0931C17.3698 17.3296 16.3047 18.5171 14.921 19.2484C14.4404 19.5024 13.948 19.7102 13.5161 19.7947L10.8489 20.0598V20.0598C9.09036 20.2347 7.38809 20.7767 5.85225 21.6509L5.71549 21.7288M1.59766 18.3285L4.49886 15.0217C4.84201 14.7202 5.89642 14.117 7.36886 14.117H14.3567V14.117C14.7477 14.117 15.1551 14.1798 15.431 14.4568C15.6711 14.6979 15.8853 15.053 15.8853 15.5209C15.8645 15.8952 15.6482 16.6501 14.9494 16.6751H9.58375",
            stroke: "inherit",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M15.1701 6.62622H11.4684L10.2345 8.47707H7.76667L6.53276 6.62622H2.83105",
            stroke: "inherit",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
        _c("path", {
          attrs: {
            d: "M4.95954 2.37549L2.83105 6.62629V10.328C2.83105 10.6552 2.96105 10.9691 3.19246 11.2005C3.42386 11.4319 3.73771 11.5619 4.06496 11.5619H13.9362C14.2634 11.5619 14.5773 11.4319 14.8087 11.2005C15.0401 10.9691 15.1701 10.6552 15.1701 10.328V6.62629L13.0416 2.37549C12.9394 2.16991 12.782 1.99691 12.5869 1.87593C12.3918 1.75495 12.1668 1.6908 11.9373 1.69067H6.06388C5.83432 1.6908 5.60935 1.75495 5.41426 1.87593C5.21917 1.99691 5.06169 2.16991 4.95954 2.37549V2.37549Z",
            stroke: "inherit",
            "stroke-width": "1.5",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_4284_2180" } }, [
          _c("rect", {
            attrs: {
              width: "22",
              height: "22",
              fill: "white",
              transform: "translate(0.0205078 0.709717)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }