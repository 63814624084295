var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "45",
        height: "32",
        viewBox: "0 0 45 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "0.5",
          y: "0.5",
          width: "44",
          height: "30.7647",
          rx: "4.79412",
          fill: "#1A1A1A",
          stroke: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M28.5582 24.3928C33.5147 24.3928 37.5327 20.4226 37.5327 15.5251C37.5327 10.6277 33.5147 6.65747 28.5582 6.65747C26.3369 6.65747 24.304 7.45493 22.7369 8.77591C21.1697 7.45497 19.1369 6.65755 16.9156 6.65755C11.9592 6.65755 7.94116 10.6277 7.94116 15.5252C7.94116 20.4227 11.9592 24.3928 16.9156 24.3928C19.137 24.3928 21.1698 23.5954 22.737 22.2744C24.3041 23.5953 26.3369 24.3928 28.5582 24.3928Z",
          fill: "#ED0006",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M22.7369 22.2741C24.6665 20.6476 25.8901 18.2274 25.8901 15.5249C25.8901 12.8223 24.6665 10.4021 22.7369 8.77562C24.3041 7.45466 26.3369 6.65723 28.5582 6.65723C33.5147 6.65723 37.5327 10.6274 37.5327 15.5249C37.5327 20.4223 33.5147 24.3925 28.5582 24.3925C26.3369 24.3925 24.3041 23.5951 22.7369 22.2741Z",
          fill: "#F9A000",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M22.7369 22.2741C24.6665 20.6476 25.89 18.2275 25.89 15.5249C25.89 12.8223 24.6665 10.4021 22.7369 8.77563C20.8073 10.4021 19.5837 12.8223 19.5837 15.5249C19.5837 18.2275 20.8073 20.6476 22.7369 22.2741Z",
          fill: "#FF5E00",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }