var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-block full-width" },
    [
      _vm.type == "default"
        ? _c(
            "v-btn",
            {
              staticClass:
                "full-width white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8 mb-2",
              class: _vm.customClass,
              attrs: {
                height: _vm.height,
                "max-width": _vm.maxWidth,
                color: "unbox_black",
                elevation: "0",
              },
              on: { click: _vm.action },
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          )
        : _vm._e(),
      _vm.type == "secondary"
        ? _c(
            "v-btn",
            {
              staticClass:
                "full-width unbox_black--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8 text-decoration-underline",
              class: _vm.customClass,
              attrs: {
                height: _vm.height,
                "max-width": _vm.maxWidth,
                elevation: "0",
              },
              on: { click: _vm.action },
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }