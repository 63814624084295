var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "light_gray", elevation: "0" } },
    [
      _c(
        "v-form",
        { ref: "loginForm" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { scols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "text-body-2 unbox_secondary--text" },
                  [_vm._v(_vm._s(_vm.$t("label.loginMessage")))]
                ),
              ]),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      label: _vm.$t("label.email"),
                      placeholder: _vm.$t("label.enterYourEmail"),
                      rules: _vm.$validation.emailRules(),
                    },
                    model: {
                      value: _vm.loginForm.email,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.loginForm,
                          "email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "loginForm.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      label: _vm.$t("label.password"),
                      type: "password",
                      placeholder: _vm.$t("label.enterYourPassword"),
                      rules: _vm.$validation.passwordRules(),
                    },
                    model: {
                      value: _vm.loginForm.password,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.loginForm,
                          "password",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "loginForm.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "space-between" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0 pt-0",
                            attrs: {
                              "on-icon": "$checkIcon",
                              "hide-details": "",
                              ripple: false,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-capitalize text-caption font-weight-bold unbox_black--text",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("label.rememberMe"))
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.loginForm.rememberMe,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "rememberMe", $$v)
                              },
                              expression: "loginForm.rememberMe",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-capitalize text-caption px-0 font-weight-bold unbox_black--text",
                              attrs: {
                                "min-width": "0",
                                height: "auto",
                                text: "",
                                ripple: false,
                              },
                              on: { click: _vm.openForgetPasswordForm },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("label.forgotPassword")) +
                                  "? "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8",
                        attrs: {
                          height: "56px",
                          "max-width": "300",
                          width: "100%",
                          color: "unbox_black",
                          elevation: "0",
                        },
                        on: { click: _vm.submitLogin },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("label.signIn")) + " ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-4 text-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "black--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8",
                        attrs: {
                          height: "56px",
                          "max-width": "300",
                          width: "100%",
                          elevation: "0",
                        },
                        on: { click: _vm.openFirstTimeLoginForm },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("label.firstTimeLogin")) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }