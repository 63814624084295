var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "111",
        height: "111",
        viewBox: "0 0 111 111",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("rect", {
        attrs: {
          x: "0.770508",
          y: "0.5",
          width: "110",
          height: "110",
          rx: "55",
          fill: "#6EE7B7",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M47.6486 66.0604L79.5852 34.3457L84.4454 39.1721L47.6486 75.7132L28.0352 56.2361L32.8953 51.4097L47.6486 66.0604Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }