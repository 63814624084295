var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "unboxFooter" } },
    [
      _c(
        "v-card",
        {
          staticClass: "pt-10 pb-3",
          attrs: { elevation: "0", color: "unbox_black", tile: "" },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "11", lg: "9" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-sm-left text-center",
                          attrs: { cols: "12", lg: "4" },
                        },
                        [
                          _c("div", { staticClass: "mb-5" }, [
                            _c("img", {
                              attrs: {
                                src: "/static/images/logo/logo_white_base.png",
                                alt: "Logo UNBOX Malaysia",
                                title: "Logo UNBOX Malaysia",
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-body-2 unbox_secondary--text mb-5 text-uppercase",
                            },
                            [_vm._v(_vm._s(_vm.$t("label.slogan")))]
                          ),
                          _c("div", { staticClass: "text-body-2" }, [
                            _c("span", { staticClass: "white--text d-block" }, [
                              _vm._v(_vm._s(_vm.$t("label.connectWithUs"))),
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-1",
                                    attrs: {
                                      icon: "",
                                      elevation: "0",
                                      color: "unbox_black",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToExternalLink(
                                          "https://api.whatsapp.com/send/?phone=601155550000&text&app_absent=0"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "contactUs",
                                      attrs: {
                                        src: "/static/images/footerContactUs/whatsapp-icon.png",
                                        alt: "WhatsApp",
                                        title: "WhatsApp | Contact Us",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-1",
                                    attrs: {
                                      icon: "",
                                      elevation: "0",
                                      color: "unbox_black",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToExternalLink(
                                          "https://www.facebook.com/unboxmalaysia/"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "contactUs",
                                      attrs: {
                                        src: "/static/images/footerContactUs/facebook-icon.png",
                                        alt: "Facebook",
                                        title: "Facebook | Contact Us",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-1",
                                    attrs: { icon: "", elevation: "0" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToExternalLink(
                                          "https://www.instagram.com/unboxmalaysia/"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "contactUs",
                                      attrs: {
                                        src: "/static/images/footerContactUs/instagram-icon.png",
                                        alt: "Instagram",
                                        title: "Instagram | Contact Us",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-16 mt-lg-0 text-sm-left text-center",
                          attrs: { cols: "12", lg: "8" },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "3",
                                    lg: "3",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("label.customerCare"))
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_secondary--text text-body-2 mb-3 cursor-pointer",
                                        on: {
                                          click: function () {
                                            return _vm.goToStaticRoute(
                                              _vm.routeName.REFUND_POLICY
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("label.refundPolicy"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_secondary--text text-body-2 mb-3 cursor-pointer",
                                        on: {
                                          click: function () {
                                            return _vm.goToStaticRoute(
                                              _vm.routeName.PRIVACY_POLICY
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("label.privacyPolicy"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_secondary--text text-body-2 mb-3 cursor-pointer",
                                        on: {
                                          click: function () {
                                            return _vm.goToStaticRoute(
                                              _vm.routeName.TERMS_AND_CONDITIONS
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("label.termsAndConditions")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_secondary--text text-body-2 mb-3 cursor-pointer",
                                        on: {
                                          click: function () {
                                            return _vm.goToStaticRoute(
                                              _vm.routeName.PROTECTION_PLAN
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "label.unboxCareTermsAndConditions"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_secondary--text text-body-2 mb-3 cursor-pointer",
                                        on: {
                                          click: function () {
                                            return _vm.goToStaticRoute(
                                              _vm.routeName.CONTACT_US
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("label.contactUs"))
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_secondary--text text-body-2 mb-3 cursor-pointer",
                                        on: {
                                          click: function () {
                                            return _vm.goToStaticRoute(
                                              _vm.routeName.LOCATE_US
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("label.findAStore"))
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "3",
                                    lg: "3",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("label.aboutUs")))]
                                  ),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_secondary--text text-body-2 mb-3 cursor-pointer",
                                        on: {
                                          click: function () {
                                            return _vm.goToStaticRoute(
                                              _vm.routeName.ABOUT_US
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("label.aboutUs")))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_secondary--text text-body-2 mb-3 cursor-pointer",
                                        on: {
                                          click: function () {
                                            return _vm.goToStaticRoute(
                                              _vm.routeName.CAREER
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("label.career")))]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "3",
                                    lg: "3",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5",
                                    },
                                    [_vm._v(_vm._s(_vm.$t("label.getTheApp")))]
                                  ),
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center app-qr-image mb-2 mx-sm-0 mx-auto",
                                      },
                                      [
                                        _c("qr-code", {
                                          attrs: {
                                            text: _vm.downloadLink,
                                            size: 120,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "unbox_secondary--text text-body-2 mb-3",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("label.scanQRorClickBelow")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center playstore-icon mb-2 mx-sm-0 mx-auto cursor-pointer",
                                        on: { click: _vm.openPlayStoreLink },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/static/images/store/playstore.png",
                                            alt: "Google Play Store",
                                            title: "Google Play Store",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center playstore-icon mb-2 mx-sm-0 mx-auto cursor-pointer",
                                        on: { click: _vm.openAppStoreLink },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/static/images/store/appstore.png",
                                            alt: "App Store",
                                            title: "App Store",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "6",
                                    md: "3",
                                    lg: "3",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("label.weAlsoAccept"))
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/fpx.png",
                                        alt: "FPX",
                                        title: "FPX | Payment Method",
                                      },
                                    }),
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/grabPay.png",
                                        alt: "GrabPay",
                                        title: "GrabPay | Payment Method",
                                      },
                                    }),
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/tng.png",
                                        alt: "Touch 'N Go E-wallet",
                                        title:
                                          "Touch 'N Go E-wallet | Payment Method",
                                      },
                                    }),
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/boostPay.png",
                                        alt: "BoostPay",
                                        title: "BoostPay | Payment Method",
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("label.availableInstallment")
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/hsbc.png",
                                        alt: "HSBC",
                                        title: "HSBC | Payment Method",
                                      },
                                    }),
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/cimb.png",
                                        alt: "CIMB",
                                        title: "CIMB | Payment Method",
                                      },
                                    }),
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/affin.png",
                                        alt: "Affin",
                                        title: "Affin | Payment Method",
                                      },
                                    }),
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/uob.png",
                                        alt: "UOB",
                                        title: "UOB | Payment Method",
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "unbox_secondary--text text--lighten-1 text-subtitle-1 mb-5",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("label.buyNowPayLater"))
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/atome.png",
                                        alt: "",
                                      },
                                    }),
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/grab.png",
                                        alt: "Grab",
                                        title:
                                          "PayLater by Grab | Payment Method",
                                      },
                                    }),
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/payLater.png",
                                        alt: "",
                                      },
                                    }),
                                    _c("img", {
                                      staticClass: "footerPayment",
                                      attrs: {
                                        src: "/static/images/footerPayment/split.png",
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "my-4" }),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "py-4",
                      attrs: { align: "center", justify: "space-between" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center text-lg-left py-4 py-lg-0",
                          attrs: {
                            cols: "12",
                            md: "auto",
                            lg: "auto",
                            order: "12",
                            "order-md": "1",
                            "order-lg": "1",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-body-2 unbox_secondary--text",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("label.copyright")) +
                                  " © " +
                                  _vm._s(_vm.year) +
                                  ", " +
                                  _vm._s(_vm.$t("label.unboxAsia")) +
                                  ". "
                              ),
                              _c("span", { staticClass: "text-uppercase" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("label.allRightsReserved"))
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }