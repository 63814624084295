var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "21",
        height: "21",
        viewBox: "0 0 21 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M9.8729 16.2939C13.5548 16.2939 16.5396 13.3091 16.5396 9.62724C16.5396 5.94534 13.5548 2.96057 9.8729 2.96057C6.19101 2.96057 3.20624 5.94534 3.20624 9.62724C3.20624 13.3091 6.19101 16.2939 9.8729 16.2939Z",
          stroke: "inherit",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M18.2061 17.9606L14.5811 14.3356",
          stroke: "inherit",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }