var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-header"),
      _c("div", { staticClass: "body" }, [_c("router-view")], 1),
      _c("app-footer"),
      _c("app-dialog", {
        attrs: {
          dialogShow: _vm.registrationLoginDialog,
          closeAction: function () {
            return (_vm.registrationLoginDialog = false)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "ma-auto" },
                  [
                    _c(
                      "v-tabs",
                      {
                        attrs: {
                          "background-color": "transparent",
                          color: "unbox_primary",
                        },
                        model: {
                          value: _vm.registrationLoginOpenTab,
                          callback: function ($$v) {
                            _vm.registrationLoginOpenTab = $$v
                          },
                          expression: "registrationLoginOpenTab",
                        },
                      },
                      _vm._l(_vm.registrationLoginTab, function (item) {
                        return _c(
                          "v-tab",
                          {
                            key: item.value,
                            staticClass: "text-capitalize text-h6",
                            attrs: {
                              ripple: false,
                              "active-class": "font-weight-bold",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("label." + item.text)) + " "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "v-tabs-items",
                      {
                        model: {
                          value: _vm.registrationLoginOpenTab,
                          callback: function ($$v) {
                            _vm.registrationLoginOpenTab = $$v
                          },
                          expression: "registrationLoginOpenTab",
                        },
                      },
                      [
                        _c("v-tab-item", [
                          _c(
                            "div",
                            { staticClass: "loginForm-tab" },
                            [_c("app-login")],
                            1
                          ),
                        ]),
                        _c("v-tab-item", [
                          _c(
                            "div",
                            { staticClass: "registrationForm-tab" },
                            [_c("app-registration")],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("app-dialog", {
        attrs: {
          dialogShow: _vm.forgotPasswordFormDialogShow,
          closeAction: function () {
            return (_vm.forgotPasswordFormDialogShow = false)
          },
          title: _vm.$t("action.forgotPassword"),
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "forgotPassswordForm-tab" },
                  [_c("app-forgot-password")],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("app-dialog", {
        attrs: {
          dialogShow: _vm.firstTimeLoginFormDialogShow,
          closeAction: function () {
            return (_vm.firstTimeLoginFormDialogShow = false)
          },
          title: _vm.$t("action.firstTimeLogin"),
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "forgotPassswordForm-tab" },
                  [_c("app-first-time-login")],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("app-dialog", {
        attrs: {
          dialogShow: _vm.createPasswordDialogShow,
          closeAction: this.pageDialog.dialogXButton,
          title:
            _vm.resetPasswordObj.purpose ==
            _vm.shared.RESET_PASSWORD_FIRST_TIME_LOGIN
              ? _vm.$t("action.createPassword")
              : _vm.$t("action.forgotPassword"),
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "createPassswordForm-tab" },
                  [_c("app-reset-password")],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("app-dialog", {
        attrs: {
          dialogShow: _vm.createPasswordLinkDialogShow,
          title: _vm.$t("action.requestPasswordLink"),
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "createPassswordForm-tab" },
                  [_c("app-create-password-link")],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("app-dialog", {
        attrs: {
          dialogShow: _vm.deliveryAddressDialogShow,
          title: _vm.deliveryAddressObj.isEdit
            ? _vm.$t("action.editAddress")
            : _vm.$t("action.addAddress"),
          closeAction: function () {
            return (_vm.deliveryAddressDialogShow =
              !_vm.deliveryAddressDialogShow)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "deliveryAddressFrom-tab" },
                  [_c("app-delivery-address")],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("app-dialog", {
        attrs: {
          dialogShow: _vm.pageDialogShow,
          title: _vm.pageDialog.title,
          closeAction: this.pageDialog.dialogXButton,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "pageMessage-tab" },
                  [
                    _c("app-page-dialog", {
                      attrs: {
                        dialogMessageTitle: _vm.pageDialog.messageTitle,
                        dialogMessage: _vm.pageDialog.message,
                        dialogButton: _vm.pageDialog.button,
                        dialogErrorCode: _vm.pageDialog.errorCode,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-snackbar",
        {
          attrs: { centered: "", color: "unbox_primary", timeout: 1500 },
          model: {
            value: _vm.snackBarShow,
            callback: function ($$v) {
              _vm.snackBarShow = $$v
            },
            expression: "snackBarShow",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackBarMessage) + " ")]
      ),
      _c("app-dialog", {
        attrs: { "dialog-show": _vm.messageDialogDisplay },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "pageMessage-tab" },
                  [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _vm.messageDialog.type === "error"
                          ? _c("dialog-exclamation")
                          : _vm._e(),
                        _vm.messageDialog.type === "success"
                          ? _c("dialog-tick")
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("app-page-dialog", {
                      attrs: {
                        dialogMessage: _vm.messageDialog.messages,
                        dialogMessageTitle: _vm.messageDialog.messageTitle,
                        dialogButton: _vm.messageDialog.buttons,
                        dialogErrorCode: _vm.messageDialog.messageErrorCode,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }