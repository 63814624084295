var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "rounded-lg",
      attrs: {
        persistent: "",
        value: _vm.dialogShow,
        "max-width": _vm.maxWidth,
      },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeAction.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "dialog-card", attrs: { color: "light_gray" } },
        [
          _c(
            "v-row",
            {
              staticClass: "dialog-header p-relative py-3",
              attrs: { "no-gutters": "" },
            },
            [
              _vm.title
                ? _c(
                    "label",
                    {
                      staticClass:
                        "title pa-3 ma-0 text-center unbox_primary--text text-capitalize d-block full-width font-weight-bold",
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  )
                : _vm._e(),
              _vm._t("header"),
              _vm.closeAction != null
                ? _c("v-icon", {
                    staticClass: "dialog-close-icon stroke--unbox_black",
                    domProps: { textContent: _vm._s("$xIcon") },
                    on: { click: _vm.closeAction },
                  })
                : _vm._e(),
            ],
            2
          ),
          _vm.dialogShow
            ? _c(
                "v-row",
                {
                  staticClass: "d-block dialog-row pb-6 px-3 px-lg-6",
                  class: _vm.customClass,
                  attrs: { "no-gutters": "" },
                },
                [_vm._t("body")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }