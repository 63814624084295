var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "28",
        height: "29",
        viewBox: "0 0 28 29",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M23.3333 26.6273C23.9776 26.6273 24.5 26.1049 24.5 25.4606C24.5 24.8162 23.9776 24.2939 23.3333 24.2939C22.689 24.2939 22.1666 24.8162 22.1666 25.4606C22.1666 26.1049 22.689 26.6273 23.3333 26.6273Z",
          stroke: "inherit",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.5 26.6273C11.1444 26.6273 11.6667 26.1049 11.6667 25.4606C11.6667 24.8162 11.1444 24.2939 10.5 24.2939C9.85571 24.2939 9.33337 24.8162 9.33337 25.4606C9.33337 26.1049 9.85571 26.6273 10.5 26.6273Z",
          stroke: "inherit",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M1.16663 2.12723H5.83329L8.95996 17.7489C9.06664 18.286 9.35885 18.7685 9.78543 19.1119C10.212 19.4553 10.7458 19.6377 11.2933 19.6272H22.6333C23.1808 19.6377 23.7146 19.4553 24.1412 19.1119C24.5677 18.7685 24.8599 18.286 24.9666 17.7489L26.8333 7.96056H6.99996",
          stroke: "inherit",
          "stroke-width": "1.5",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }