var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "13",
        height: "12",
        viewBox: "0 0 13 12",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6.46545 9.1281L8.82983 10.6259C9.13208 10.8171 9.50708 10.5325 9.41745 10.1796L8.73457 7.49273C8.71529 7.41788 8.71751 7.33912 8.74099 7.26548C8.76448 7.19185 8.80827 7.12633 8.86732 7.07648L10.9876 5.3121C11.2658 5.08035 11.1226 4.61798 10.7644 4.59473L7.99582 4.41473C7.92128 4.40944 7.84978 4.38309 7.78963 4.33874C7.72948 4.29439 7.68317 4.23387 7.65608 4.16423L6.62333 1.56398C6.5952 1.49008 6.54529 1.42647 6.48019 1.38159C6.41509 1.33672 6.33789 1.31268 6.25883 1.31268C6.17976 1.31268 6.10256 1.33672 6.03746 1.38159C5.97236 1.42647 5.92245 1.49008 5.89433 1.56398L4.86158 4.16423C4.83454 4.23394 4.78825 4.29454 4.7281 4.33896C4.66795 4.38337 4.59641 4.40978 4.52183 4.4151L1.7532 4.5951C1.39545 4.61798 1.25145 5.08035 1.53008 5.3121L3.65033 7.07685C3.70932 7.12668 3.75306 7.19213 3.77654 7.26569C3.80003 7.33925 3.80229 7.41794 3.78308 7.49273L3.15008 9.9846C3.04245 10.408 3.49283 10.7496 3.85508 10.5197L6.05258 9.1281C6.11434 9.08883 6.18601 9.06798 6.2592 9.06798C6.33239 9.06798 6.40406 9.08883 6.46583 9.1281H6.46545Z",
          fill: "#CCCCCC",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }