var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "41",
        height: "41",
        viewBox: "0 0 41 41",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_3998_2856)" } }, [
        _c("path", {
          attrs: {
            d: "M36.3135 35.1002L34.0117 9.15941C33.9703 8.64605 33.5397 8.2569 33.0181 8.2569H28.1661C28.1578 3.94309 24.6471 0.432434 20.3333 0.432434C16.0195 0.432434 12.5089 3.94309 12.5006 8.2569H7.64859C7.13524 8.2569 6.70469 8.64605 6.65501 9.15941L4.35321 35.1002C4.35321 35.1333 4.35321 35.1582 4.35321 35.1913C4.35321 38.081 7.01105 40.4324 10.2733 40.4324H30.3934C33.6556 40.4324 36.3135 38.081 36.3135 35.1913C36.3135 35.1582 36.3135 35.1333 36.3135 35.1002ZM20.3333 2.4196C23.5542 2.4196 26.1706 5.03604 26.1789 8.2569H14.4878C14.496 5.03604 17.1125 2.4196 20.3333 2.4196ZM30.3934 38.437H10.2733C8.12055 38.437 6.36522 37.0046 6.34038 35.2244L8.55938 10.2441H12.5006V13.7299C12.5006 14.2764 12.9477 14.7235 13.4942 14.7235C14.0406 14.7235 14.4878 14.2764 14.4878 13.7299V10.2441H26.1789V13.7299C26.1789 14.2764 26.626 14.7235 27.1725 14.7235C27.719 14.7235 28.1661 14.2764 28.1661 13.7299V10.2441H32.1073L34.3263 35.2327C34.3015 37.0046 32.5461 38.437 30.3934 38.437Z",
            fill: "#C7A87E",
          },
        }),
        _c("path", {
          attrs: {
            d: "M24.4649 21.1156L18.6028 26.9777L16.2099 24.5848C15.8208 24.1957 15.1915 24.1957 14.8024 24.5848C14.4132 24.974 14.4132 25.6032 14.8024 25.9924L17.899 29.0891C18.0895 29.2795 18.3461 29.3789 18.6028 29.3789C18.8595 29.3789 19.1079 29.2795 19.3066 29.0891L25.8725 22.5231C26.2617 22.134 26.2617 21.5047 25.8725 21.1156C25.4834 20.7347 24.8541 20.7347 24.4649 21.1156Z",
            fill: "#C7A87E",
          },
        }),
      ]),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_3998_2856" } }, [
          _c("rect", {
            attrs: {
              width: "40",
              height: "40",
              fill: "white",
              transform: "translate(0.333374 0.432434)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }