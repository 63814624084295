var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "light_gray", elevation: "0" } },
    [
      _c(
        "v-form",
        { ref: "registrationForm" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { md: "6", cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "text-body-2 unbox_secondary--text" },
                  [_vm._v(_vm._s(_vm.$t("label.registrationMessage")))]
                ),
              ]),
              _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "12" } }),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { md: "6", cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      label: _vm.$t("label.firstName"),
                      placeholder: _vm.$t("label.firstName"),
                      rules: _vm.$validation.nameRules(),
                    },
                    model: {
                      value: _vm.registrationForm.firstName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.registrationForm,
                          "firstName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "registrationForm.firstName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { md: "6", cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      label: _vm.$t("label.lastName"),
                      placeholder: _vm.$t("label.lastName"),
                      rules: _vm.$validation.nameRules(),
                    },
                    model: {
                      value: _vm.registrationForm.lastName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.registrationForm,
                          "lastName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "registrationForm.lastName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { md: "6", cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      prefix: _vm.registrationForm.mobileNumberCountryCode,
                      label: _vm.$t("label.mobileNumber"),
                      placeholder: _vm.$t("label.mobileNumber"),
                      rules: _vm.$validation.mobileNumberRules(),
                    },
                    model: {
                      value: _vm.registrationForm.mobileNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.registrationForm,
                          "mobileNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "registrationForm.mobileNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-1", attrs: { md: "6", cols: "12" } },
                [
                  _c("app-form-field", {
                    attrs: {
                      label: _vm.$t("label.email"),
                      placeholder: _vm.$t("label.email"),
                      rules: _vm.$validation.emailRules(),
                    },
                    model: {
                      value: _vm.registrationForm.email,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.registrationForm,
                          "email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "registrationForm.email",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8",
                        attrs: {
                          height: "56px",
                          "max-width": "300",
                          width: "100%",
                          color: "unbox_black",
                          elevation: "0",
                        },
                        on: { click: _vm.submitRegistration },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("label.signUp")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }