var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "fill-height py-0" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-0 pb-2 font-weight-bold title text-center" },
                [_vm._v(_vm._s(_vm.dialogMessageTitle))]
              ),
              _vm._l(_vm.dialogMessage, function (message, index) {
                return _c(
                  "v-card-text",
                  {
                    key: message + "-" + index,
                    staticClass: "pa-0 pb-2 body-1",
                  },
                  [_vm._v(_vm._s(message))]
                )
              }),
              _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _vm._l(_vm.dialogButton, function (button, index) {
                    return [
                      _c(
                        "div",
                        { key: index },
                        [
                          _c("app-button", {
                            attrs: {
                              type: button.type,
                              action: button.action,
                              customClass: button.class,
                              title: button.title,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm.dialogErrorCode != ""
                ? _c("div", { staticClass: "d-none text-right text-caption" }, [
                    _c("span", [_vm._v(_vm._s(_vm.dialogErrorCode))]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }