<template>
  <div id="unboxHeader" ref="unboxHeader">
    <v-card elevation="0" color="unbox_black" tile class="d-none d-sm-block">
      <v-row class="py-2" justify="center" no-gutters>
        <v-col cols="9">
          <v-row align="center">
            <v-col cols="10">
              <div class="font-weight-bold neutral_white--text text-body-2">
                {{ $t(`label.welcomeToEcommerceStore`) }}
                <div class="d-inline-block" v-if="!isLoggedIn">
                  <v-btn class="text-capitalize pa-0 mx-0 v-align-initial" height="auto" text :ripple="false" @click="openRegistration">
                    <span class="unbox_green--text font-weight-bold text-body-2 px-0">{{ $t(`label.joinFree`) }}</span>
                  </v-btn>
                  {{ $t(`label.or`) }}
                  <v-btn class="text-capitalize pa-0 mx-0 v-align-initial" height="auto" min-width="0" text :ripple="false" @click="openLogin">
                    <span class="unbox_green--text font-weight-bold text-body-2 px-0">{{ $t(`label.signIn`) }}</span>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="d-inline-block" v-if="!isLoggedIn">
                <v-btn class="text-capitalize neutral_white--text px-0 mx-2" height="100%" min-width="0" text :ripple="false" @click="openLogin">
                  <span class="text-body-2 px-1">{{ $t(`label.login`) }}</span>
                </v-btn>
              </div>
              <v-menu v-if="isLoggedIn" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="text-capitalize neutral_white--text pa-0 mx-2" min-width="0" height="auto" text :ripple="false">
                    <v-icon class="stroke--neatural_white" small v-text="'$accountIcon'"></v-icon>
                    <span class="text-body-2 px-1">{{ $t(`label.account`) }}</span>
                    <v-icon class="stroke--neatural_white" small v-text="attrs['aria-expanded'].toUpperCase() == 'TRUE' ? '$chevronUpIcon' : '$chevronDownIcon'"></v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="goToMyAccount">
                    <span class="text-capitalize text-body-2">{{ $t(`label.myAccount`) }}</span>
                  </v-list-item>
                  <v-list-item @click="goToMyOrder">
                    <span class="text-capitalize text-body-2">{{ $t(`label.myOrders`) }}</span>
                  </v-list-item>
                  <v-list-item @click="logout">
                    <span class="text-capitalize text-body-2">{{ $t(`label.logout`) }}</span>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <v-card elevation="0" color="#C7A87E" tile>
      <v-row class="py-3" justify="center" no-gutters>
        <v-col cols="11" md="9">
          <v-row align="center" justify="space-between" no-gutters>
            <v-col cols="6" offset="3" md="3" offset-md="0" class="text-center text-md-left">
              <img class="cursor-pointer" @click="goToHome" src="/static/images/logo/Unbox Logo_White Base 1.png" alt="UNBOX Malaysia Logo" title="UNBOX Malaysia Logo" />
            </v-col>
            <v-col cols="3" class="text-right d-block d-sm-none">
              <div v-if="isLoggedIn">
                <v-btn icon class="d-inline-block text-body-1 ml-sm-2" tile elevation="0" @click="goToCartPage">
                  <v-badge :color="cart.items.length <= 0 ? 'transparent' : 'red'" :content="cart.items.length" offset-x="10" offset-y="10">
                    <v-icon class="stroke--unbox_black" v-text="'$cartIcon'"></v-icon>
                  </v-badge>
                </v-btn>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon class="d-inline-block text-body-1 ml-sm-2" tile elevation="0">
                      <v-icon class="mt-1 stroke--unbox_black" v-text="'$accountIcon'"></v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-btn class="text-capitalize pa-0" height="auto" min-width="0" text :ripple="false" @click="goToMyAccount">
                        <span class="text-body-2">{{ $t(`label.myAccount`) }}</span>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-btn class="text-capitalize pa-0" height="auto" min-width="0" text :ripple="false" @click="goToMyOrder">
                        <span class="text-body-2">{{ $t(`label.myOrders`) }}</span>
                      </v-btn>
                    </v-list-item>
                    <v-list-item v-if="isLoggedIn">
                      <v-btn class="text-capitalize pa-0" height="auto" min-width="0" text :ripple="false" @click="logout">
                        <span class="text-body-2">{{ $t(`label.logout`) }}</span>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-btn v-else icon class="d-inline-block text-body-1 ml-sm-2" tile elevation="0" @click="openLogin">
                <v-icon class="stroke--unbox_black" v-text="'$accountIcon'"></v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="5">
              <v-form ref="headerKeywordForm" @submit.prevent="searchProductByKeyword">
                <v-combobox
                  ref="keywordSearchRef"
                  :menu-props="{ offsetY: true }"
                  :items="skuData"
                  v-model="searchKeywordGroupId"
                  :search-input.sync="searchKeyword"
                  hide-no-data
                  outlined
                  full-width
                  hide-details
                  class="rounded-sm subscribe-text-field my-3"
                  background-color="white"
                  :placeholder="`5G Phone`"
                  @keydown="searchProductByKeywordKeyDown"
                >
                  <template v-slot:prepend-inner>
                    <v-icon small class="mt-1 d-none d-sm-inline-block stroke--unbox_black" v-text="'$searchIcon'"></v-icon>
                  </template>
                  <template v-slot:append>
                    <v-btn class="white--text full-height text-body-1 rounded-r-sm font-weight-bold text-capitalize px-8 mt-n4" height="56px" color="unbox_black" elevation="0" type="submit">
                      <span class="d-none d-sm-inline-block">{{ $t(`label.search`) }}</span>
                      <v-icon class="d-inline-block d-sm-none mt-1 stroke--white" v-text="'$searchIcon'"></v-icon>
                    </v-btn>
                  </template>
                </v-combobox>
              </v-form>
            </v-col>
            <v-col cols="3" md="3" class="text-right d-none d-sm-block">
              <div v-if="isLoggedIn" class="cursor-pointer d-inline-block" @click="goToCartPage">
                <v-badge :color="cart.items.length <= 0 ? 'transparent' : 'red'" :content="cart.items.length" offset-x="10" offset-y="10">
                  <v-icon class="stroke--unbox_black" v-text="'$cartIcon'" style="vertical-align: unset"></v-icon>
                </v-badge>

                <div class="d-inline-block pl-3">
                  <div class="text-body-2">{{ $t(`label.shoppingCart`) }}:</div>
                  <div class="font-weight-bold">{{ $shared.formatCurrency(cartTotalPrice) }}</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="11" sm="9" class="mt-3">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="$vuetify.breakpoint.smAndUp"
                :to="{ name: routeName.SHOP }"
                plain
                class="mr-4 mr-md-10 text-uppercase text-caption text-md-body-2 font-weight-bold px-0"
                link
                min-width="0"
                height="auto"
                text
                :ripple="false"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t(`label.newArrivals`) }}
              </v-btn>
            </template>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mr-4 mr-md-10 text-uppercase text-caption text-md-body-2 font-weight-bold px-0" min-width="0" height="auto" text :ripple="false">
                <span class="">{{ $t(`label.installmentPlan`) }}</span>
                <v-icon class="stroke--unbox_black" small v-text="attrs['aria-expanded'].toUpperCase() == 'TRUE' ? '$chevronUpIcon' : '$chevronDownIcon'"></v-icon>
              </v-btn>
            </template>

            <v-list color="unbox_primary">
              <v-list-item @click="goToBnpl">
                <span class="text-capitalize text-body-2">{{ $t(`label.buyNowPayLaterAbbr`) }}</span>
              </v-list-item>
              <v-list-item @click="goToAeonCredit">
                <span class="text-capitalize text-body-2">{{ $t(`label.aeonCredit`) }}</span>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mr-4 mr-md-10 text-uppercase text-caption text-md-body-2 font-weight-bold px-0" min-width="0" height="auto" text :ripple="false">
                <span class="">{{ $t(`label.deals`) }}</span>
                <v-icon class="stroke--unbox_black" small v-text="attrs['aria-expanded'].toUpperCase() == 'TRUE' ? '$chevronUpIcon' : '$chevronDownIcon'"></v-icon>
              </v-btn>
            </template>

            <v-list color="unbox_primary">
              <!-- <v-list-item @click="goToSnbl">
                <span class="text-capitalize text-body-2">{{ $t(`label.saveNowBuyLaterAbbr`) }}</span>
              </v-list-item> -->
              <v-list-item @click="goToTradeIn">
                <span class="text-capitalize text-body-2">{{ $t(`label.unboxTradeIn`) }}</span>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :to="{ name: routeName.PERSONAL_LOAN }"
                plain
                class="mr-4 mr-md-10 text-uppercase text-caption text-md-body-2 font-weight-bold px-0"
                link
                min-width="0"
                height="auto"
                text
                :ripple="false"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t(`label.financing`) }}
              </v-btn>
            </template>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :to="{ name: routeName.CREDIT_CARD }"
                plain
                class="mr-4 mr-md-10 text-uppercase text-caption text-md-body-2 font-weight-bold px-0"
                link
                min-width="0"
                height="auto"
                text
                :ripple="false"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t(`label.creditCards`) }}
              </v-btn>
            </template>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="$vuetify.breakpoint.smAndUp"
                :to="{ name: routeName.CLASS_REGISTRATION }"
                class="text-uppercase text-caption text-md-body-2 font-weight-bold px-0"
                plain
                link
                min-width="0"
                height="auto"
                text
                :ripple="false"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t(`label.unboxYourself`) }}
              </v-btn>
            </template>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { LOOKUPS_PRODUCT_GROUPS } from '@/store/lookup.module'
import { ROUTE_NAME } from '@/constants/route.constants'
import { sharedHelper } from '@/utils'
import { SHARED } from '@/constants'
import { debounce } from 'lodash'

export default {
  name: 'AppHeader',
  props: {},
  data: () => ({
    routeName: ROUTE_NAME,
    languagues: [{ languague: 'Cn' }, { languague: 'Bm' }],
    isLoading: false,
    searchKeyword: null,
    searchKeywordGroupId: ''
  }),
  computed: {
    isLoggedIn() {
      return this.$store.state.customer.isLoggedIn
    },
    skuData() {
      return this.$store.state.lookup.productGroups
    },
    cartItems() {
      return this.$store.state.order.cart.items
    },
    cartTotalPrice() {
      return this.$store.state.product.totalCartPrice
    },
    cart() {
      let t = this.$store.state.order.cartWithPrices
      for (let i = 0; i < t.items.length; i++) {
        if (t.items[i] === undefined) {
          t.items.splice(i, 1)
        }
      }
      return t
    }
  },
  watch: {
    searchKeyword() {
      this.debouncedSearch()
    },
    searchKeywordGroupId() {
      if (this.searchKeywordGroupId != null) {
        let product = this.skuData.find((x) => x.value == this.searchKeywordGroupId.value)
        if (product != undefined) {
          let productName = product.text.replace(/\s+/g, '-').toLowerCase()
          this.$router.push({
            name: ROUTE_NAME.SHOP_PRODUCT,
            params: {
              productName: `${productName}-${SHARED.PRODUCT_GROUP_PREFIX}${product.value}`
            }
          })
        }
      }
    },
    $route(from, to) {
      let keyword = this.$route.query.keyword
      if (keyword == undefined) {
        this.$refs.headerKeywordForm.reset()
      } else {
        this.searchKeyword = keyword
      }
    }
  },
  methods: {
    openRegistration() {
      this.$root.$master.openRegistration()
    },
    openLogin() {
      this.$root.$master.openLogin()
    },
    goToHome() {
      this.$router.push({ name: ROUTE_NAME.HOME })
    },
    goToMyAccount() {
      if (!this.isLoggedIn) {
        this.openLogin()
      } else {
        this.$router.push({ name: ROUTE_NAME.ACCOUNT })
      }
    },
    goToMyOrder() {
      if (!this.isLoggedIn) {
        this.openLogin()
      } else {
        this.$router.push({ name: ROUTE_NAME.HISTORY_HISTORY })
      }
    },
    goToBnpl() {
      this.$router.push({ name: ROUTE_NAME.BUY_NOW_PAY_LATER })
    },
    goToAeonCredit() {
      const formLink = {
        development: 'https://form-dev.unbox.com.my/aeon-credit/info',
        staging: 'https://form-stage.unbox.com.my/aeon-credit/info',
        production: 'https://form.unbox.com.my/aeon-credit/info'
      }
      window.open(formLink[process.env.NODE_ENV], '_self')
    },
    goToSnbl() {
      this.$router.push({ name: ROUTE_NAME.BANNER_REDIRECT_URL_SNBL_LANDING })
    },
    goToTradeIn() {
      this.$router.push({ name: ROUTE_NAME.TRADE_IN })
    },
    logout() {
      this.$root.$master.logout()
    },
    searchProductByKeywordKeyDown(e) {
      if (e.keyCode == 13) {
        this.searchProductByKeyword()
      }
    },
    searchProductByKeyword() {
      const clearSearch = () => {
        this.$refs.keywordSearchRef.clearableCallback()
        this.$refs.keywordSearchRef.setSelectedItems()
      }
      let currentRoute = this.$route
      sharedHelper.setShopSearchFilter('keyword', this.searchKeyword)
      if (currentRoute.name == ROUTE_NAME.SHOP) {
        let routeInfoQuery = JSON.parse(JSON.stringify(this.$route.query))
        routeInfoQuery['keyword'] = this.searchKeyword
        this.$router
          .push({
            name: ROUTE_NAME.SHOP,
            query: routeInfoQuery
          })
          .then(clearSearch)
      } else {
        this.$router
          .push({
            name: ROUTE_NAME.SHOP,
            query: {
              keyword: this.searchKeyword
            }
          })
          .then(clearSearch)
      }
    },
    goToCartPage() {
      this.$router.push({ name: ROUTE_NAME.SHOP_CART })
    },
    debouncedSearch: debounce(async function () {
      this.$root.$master.searchKeyword = this.searchKeyword
      if (this.skuData.length > 0) return
      let data = {}
      await this.$store.dispatch(LOOKUPS_PRODUCT_GROUPS, { data })
    }, 500)
  }
}
</script>
