var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "41",
        height: "34",
        viewBox: "0 0 41 34",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M29.7554 32.4324C35.4133 32.4324 40 27.7515 40 21.9772C40 16.203 35.4133 11.522 29.7554 11.522C24.0975 11.522 19.5108 16.203 19.5108 21.9772C19.5108 27.7515 24.0975 32.4324 29.7554 32.4324Z",
          stroke: "#C7A87E",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M29.7554 15.7041V21.9772L33.8533 24.0683",
          stroke: "#C7A87E",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M2.25446 4.69415C2.25446 3.34949 3.33115 2.28021 4.59963 2.28021H31.361C32.6295 2.28021 33.7062 3.34949 33.7062 4.69415V9.07099C34.3882 9.15479 35.0559 9.28578 35.7062 9.46067V4.69415C35.7062 2.28348 33.7723 0.280212 31.361 0.280212H4.59963C2.18841 0.280212 0.254456 2.28348 0.254456 4.69415V25.1778C0.254456 27.5885 2.18841 29.5917 4.59963 29.5917H18.0701C17.8243 28.9454 17.6206 28.2775 17.4626 27.5917H4.59963C3.33115 27.5917 2.25446 26.5225 2.25446 25.1778V4.69415Z",
          fill: "#C7A87E",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }